/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Link, NavLink } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className="footer">
		<div className="container">
			<GridContainer>
	            <GridItem xs={12} sm={12} md={3} lg={2}>
	            	<ul className="footerLinks">
	            		{/* <li><NavLink to="/privacy">Privacy Policy</NavLink></li> */}
	            		<li><NavLink to="/terms">Terms of Services</NavLink></li>
	            	</ul>
	            </GridItem>
	            <GridItem xs={12} sm={12} md={3} lg={2}>
	            	<ul className="footerLinks">
	            		<li><NavLink to="/edit-profile">My Profile</NavLink></li>
	            		<li><NavLink to="/my-items">My Items</NavLink></li>
	            	</ul>
	            </GridItem>
	            <GridItem xs={12} sm={12} md={3} lg={2}>
	            	<ul className="footerLinks">
	            		<li><NavLink to="/support-ticket">Support Ticket</NavLink></li>
	            		<li><NavLink to="/faq">FAQ</NavLink></li>
	            	</ul>
	            </GridItem>
	            <GridItem xs={12} sm={12} md={3} lg={6}>
	            	<div className="rightFooter">
	            		<ul>
	            			<li><a href="#" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
	            			<li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
	            			<li><a href="#" target="_blank"><i class="fab fa-medium-m"></i></a></li>
	            			<li><a href="#" target="_blank"><i class="fab fa-discord"></i></a></li>
	            		</ul>
	            		<p>Copyright © 2022, <span>Operon Orginis.</span> All Rights Reserved.</p>
	            	</div>
	            </GridItem>
	        </GridContainer>
		</div>
	</footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
