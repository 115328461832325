import React, { useEffect, useState, useRef, useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "./../../assets/jss/material-kit-react/views/landingPage.js";
import Burn from "./burn";
import ChangepRice from "./priceupdate";
import Transfer from "./transfer";
import Putonsale from "./putonsale";
import Notforsale from "./notforsale";
import Web3 from "web3";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import "@metamask/legacy-web3";
import ABI from "../../ABI/ABI.json";
import ABIORE from "../../ABI/ABIORE.json";
import ABIZORO from "../../ABI/ABIZORO.json";
import ABINFT from "../../ABI/ABINFT.json";
import config from "../../config/config";
import { toast } from "react-toastify";
import ProductContext from "../ProductContext";
import { updatePurchase } from "../../actions/users";
import loadingimg  from "../../assets/images/loading.gif"
toast.configure();
let toasterOption = config.toasterOption;
var setacc = [];
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ProductInfo(props) {
  const classes = useStyles();
  const transferRef = useRef();
  const productContext = useContext(ProductContext);
  var dataNft = productContext.dataNft;
  var singdataNft = productContext.singdataNft;
  var ownerDetails = productContext.ownerDetails;
  const { getNftdataID } = props;
  const [quantity, setquantity] = useState("");
  const [youNeedtoPay, setyouNeedtoPay] = useState(
    singdataNft ? singdataNft.price : 0
  );
  const [submitButton, setsubmitButton] = React.useState("");
  const [getBalanceofToken, setyoutokenBalance] = useState(0);
  const [getBalanceofZOROToken, setyouZOROtokenBalance] = useState(0);
  const [currentPurchase, setcurrentPurchase] = useState();
  const [notforsale, setnotforsale] = useState();
  const [servicefee, setservicefee] = useState("");
  const cancelfunction = () => {
    setquantity("");
    setyouNeedtoPay(0);
  };
  const changeQuan = (quan) => {
    let qty = parseInt(quan.target.value);
    if (
      quan &&
      quan.target &&
      typeof quan.target.value != "undefined" &&
      quan.target.name
    ) {
      var value = quan.target.value;
      if (value != "" && isNaN(value) === false && value > 0) {
        setquantity(value);
      } else {
        setquantity("");
      }
    }
    if(currentPurchase.tokentype === 2){
    var resultvalue = qty * currentPurchase.price;
    setyouNeedtoPay(resultvalue);
    }else{
    var resultvalue = qty * currentPurchase.price;
    var avalue = (resultvalue * servicefee) / 100;
    var bvalue = resultvalue + avalue;
    setyouNeedtoPay(bvalue);
    }
  };

  const inputChange = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      if (value != "" && isNaN(value) === false && value > 0) {
        setquantity(value);
      } else {
        setquantity("");
      }
    }
  };

  async function setcurrentData(item) {
    setcurrentPurchase(item);
  }

  async function setsale(item) {
    setnotforsale(item);
  }

  async function convert(n) {
    try {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    } catch (err) {
        return 0;
    }

}

  async function buyNow() {
    if (quantity <= 0 || quantity === "") {
      toast.warning("please Enter valid Quantity", toasterOption);
      setsubmitButton("");
      return false;
    }
    if (currentPurchase.qty < quantity) {
      toast.warning("please enter valid Number of Quantity");
      setsubmitButton("");
      return false;
    }
    setsubmitButton(1);
    var web3 = new Web3(window.ethereum);
    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
    if(currentPurchase.tokentype === 0 || currentPurchase.tokentype === 1){
    const getFees = await Contract.methods.getFees().call();
    setservicefee(getFees / 1000000000000000000);
    var feesValue = (
      (currentPurchase.price * (getFees / 1000000000000000000)) /
      100
    ).toFixed(10);
    var fees =
      currentPurchase.price * 1000000000000000000 +
      feesValue * 1000000000000000000;
      var valuePyableAmount = await convert(fees * quantity);

    // var nftTotalPrice=(currentPurchase.price * 1000000000000000000)*quantity;
    // var _adminfee=(nftTotalPrice * 5000000000000000000) / 10**20;
    // var msgvalue =nftTotalPrice + _adminfee;
    // setmsgvalue(msgvalue);
    // setvaluePyableAmountstate(valuePyableAmount);
    if (currentPurchase.tokentype === 0) {
      var ContractORE = new web3.eth.Contract(ABIORE, config.ORE);
      var BalanceOf = await ContractORE.methods.balanceOf(setacc[0]).call();
      if (BalanceOf < valuePyableAmount) {
        toast.error("Your balance is too low", toasterOption);
        setsubmitButton("");
        return false;
      }

      var allowance = await ContractORE.methods
        .allowance(setacc[0], config.NFTCONTRACT)
        .call();

      // var valuess = await Contract.methods.viewOfferingNFT("0xe62a8bd6723c61c03be21cfcb87c8a0df7e4c929322eaffc6294847a3d601add").call();

      if (allowance <= valuePyableAmount || 1 === 1) {
        var allow = valuePyableAmount;
        try {
          var setAllowance = await ContractORE.methods
            .approve(config.NFTCONTRACT, allow.toString())
            .send({
              from: setacc[0],
            });
        } catch {
          toast.error("User cancelled payment", toasterOption);
          setsubmitButton("");
          return false;
        }

      }
    }

  }
    var ContractNFT = new web3.eth.Contract(ABINFT, config.NFTCONTRACTMINT);

    var viewOfferng = await Contract.methods
      .viewOfferingNFT(currentPurchase.offeringID)
      .call();



    var balanceOf = await ContractNFT.methods
      .balanceOf(currentPurchase.userId, currentPurchase.tokenId)
      .call();

    if (parseInt(balanceOf) < parseInt(quantity)) {
      toast.error("Owner is not having enough balance", toasterOption);
      setsubmitButton("");
      return false;
    }

    if (currentPurchase.tokentype === 0) {
      let availQuantity = await Contract.methods
        .purchaseOffering(currentPurchase.offeringID, quantity)
        .send({
          from: setacc[0],
        })
        .then(async (result, Error) => {
          if (Error) {

          }

          var reqData = {
            qty: quantity,
            buyer: setacc[0],
            id: currentPurchase._id,
          };
          var resultSave = await updatePurchase(reqData);


          toast.success("Successfully order created", toasterOption);
          await getNftdataID();
          setTimeout(
            () =>
              (window.location.href =
                config.fronturl + "Product-info/" + currentPurchase.tokenId),
            1200
          );

        })
        .catch((error) => {
          toast.error("User cancelled payment", toasterOption);
          setsubmitButton("");
          return false;

        });

    } else if(currentPurchase.tokentype === 2){
      var ContractZORO = new web3.eth.Contract(ABIZORO, config.ZORO);
      var BalanceOf = await ContractZORO.methods.balanceOf(setacc[0]).call();

      if (BalanceOf < currentPurchase.price) {
        toast.error("Your ZORO balance is too low", toasterOption);
        setsubmitButton("");
        return false;
      }
      let availQuantity = await Contract.methods
        .purchaseOffering(currentPurchase.offeringID, quantity)
        .send({
          from: setacc[0],
        })
        .then(async (result, Error) => {
          if (Error) {

          }

          var reqData = {
            qty: quantity,
            buyer: setacc[0],
            id: currentPurchase._id,
          };
          var resultSave = await updatePurchase(reqData);


          toast.success("Successfully order created", toasterOption);
          await getNftdataID();
          setTimeout(
            () =>
              (window.location.href =
                config.fronturl + "Product-info/" + currentPurchase.tokenId),
            1200
          );

        })
        .catch((error) => {
          toast.error("User cancelled payment", toasterOption);
          setsubmitButton("");

          return false;
        });

    }else {
      
      let availQuantity = Contract.methods
        .purchaseOffering(currentPurchase.offeringID, quantity)
        .send({
          from: setacc[0],
          value: valuePyableAmount,
        })
        .then(async (result) => {
          var reqData = {
            qty: quantity,
            buyer: setacc[0],
            id: currentPurchase._id,
          };

          var resultSave = await updatePurchase(reqData);


          toast.success("Successfully purchased", toasterOption);
          await getNftdataID();
          setTimeout(
            () =>
              (window.location.href =
                config.fronturl + "Product-info/" + currentPurchase.tokenId),
            1200
          );
        })
        .catch((error) => {
          toast.error("User cancelled payment", toasterOption);

          setsubmitButton("");
        });
    }
  }
  async function getTokenBalance() {
    var web3 = new Web3(window.ethereum);
    setacc = await web3.eth.getAccounts();

    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
    var ContractORE = new web3.eth.Contract(ABIORE, config.ORE);
    var getBalanceofsToken = await ContractORE.methods
      .balanceOf(setacc[0])
      .call();
    setyoutokenBalance(getBalanceofsToken / 1000000000000000000);
    var ContractZORO = new web3.eth.Contract(ABIZORO, config.ZORO);
    var getBalanceofZOROTokens = await ContractZORO.methods
      .balanceOf(setacc[0])
      .call();
      setyouZOROtokenBalance(getBalanceofZOROTokens / 1000000000000000000);
    const getFees = await Contract.methods.getFees().call();
    setservicefee(getFees / 1000000000000000000);
  }
  useEffect(() => {
    getTokenBalance();
  }, []);

  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      var ValidateError = {};
    } else {
      setquantity("");
    }
  };
  return (
    <div className={classes.pageHeader + " inner_pageheader"}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <div className="single_collectible">
              <a class="mb-0">
                {!singdataNft && <img
                    src={loadingimg}
                    className="img-fluid "
                  />}
                {singdataNft.tokenName === "mp4" ? (
                  <>
                    <video
                      src={singdataNft.imageDataUrl}
                      alt="Collections"
                      className="img-fluid"
                      controls
                    />
                  </>
                ) : singdataNft.tokenName === "mp3" ? (
                  <>
                    <img
                      src={require("../../assets/images/audio-large.jpg")}
                      alt="Collections"
                      className="img-fluid"
                    />
                    <audio
                      src={singdataNft.imageDataUrl}
                      alt=""
                      className="img-fluid "
                      controls
                    />
                  </>
                ) : (
                  <img
                    src={singdataNft.imageDataUrl}
                    className="img-fluid "
                  />
                )}
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <div className="contentBg">
              {ownerDetails && ownerDetails.qty >= 0 && (
                <div className="productInfoTitle menu_dropdown">
                 
                  <h2>{singdataNft && singdataNft.name}</h2>
                </div>
              )}
              <div className="productDescription">
                <p>{singdataNft && singdataNft.descp}</p>
              </div>
              <div className="productTokenDetails productTokenDetailsHead">
                <div className="productOwner">
                  <h4>Owner</h4>
                </div>
                <div className="productOwner">
                  <h4>Available qty</h4>
                </div>
                <div className="productOwner">
                  <h4>Price</h4>
                </div>
              </div>
              {dataNft &&
                dataNft.map((item, index) => {

                  if((item.userId === setacc[0] && item.status === 0)||item.status === 1){
                    return (
                      <div className="productTokenDetails">
                        <div className="productOwner">
                          <h3 className="ownerProfileName">
                            <img
                              src={require("../../assets/images/iconNft.png")}
                              alt=""
                              className="img-fluid"
                            />
                            <span>{item.userId.substring(0, 10) + ".."}</span>
                          </h3>
                        </div>
                        <div className="productOwner">
                          <h3 className="ownerProfileName">
                            <img
                              src={require("../../assets/images/cryptoSymbol.jpg")}
                              alt=""
                              className="img-fluid"
                            />
                            <span>{item.qty} </span>
                          </h3>
                        </div>
                        <div className="productOwner">
                          <h3 className="ownerProfileName">
                            <img
                              src={require("../../assets/images/cryptoSymbol.jpg")}
                              alt=""
                              className="img-fluid"
                            />
                            <span>
                              {item.price} {item.tokentype === 1 ? "BNB" :item.tokentype === 2? "ZORO":"ORO"}
                            </span>
                          </h3>
                        </div>
                        {localStorage.getItem("ronergodinalklz") === "yes" && (
                          <div className="buyNowProductInfo mb-0">
                            {item.userId !== setacc[0] && item.status === 1 && (
                              <Button
                                className={classes.navLink + " create_btn px-5"}
                                data-toggle="modal"
                                data-target="#checkout_modal"
                                onClick={() => setcurrentData(item)}
                              >
                                Buy Now
                              </Button>
                            )}
                            {item.userId === setacc[0] && item.status === 1 && (
                              <div className="productInfoTitle menu_dropdown">
                                {localStorage.getItem("ronergodinalklz") ===
                                  "yes" && (
                                  <CustomDropdown
                                    noLiPadding
                                    buttonText={
                                      <div>
                                        <Button
                                          className={
                                            classes.navLink + " create_btn"
                                          }
                                        >
                                          <i class="fas fa-ellipsis-h"></i>
                                        </Button>
                                      </div>
                                    }
                                    dropdownList={[
                                      <div className="noti_parent">
                                        {item && item.status === 0 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#transfer_sale_modal"
                                            // onClick={() => transferRef.current.openModal()}
                                            className={classes.dropdownLink}
                                          >
                                            Transfer
                                          </a>
                                        )}
                                        {1===1 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#burn_nft_modal"
                                            onClick={() => setsale(item)}
                                            className={classes.dropdownLink}
                                          >
                                            Burn NFT
                                          </a>
                                        )}
                                        {item && item.status === 0 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#put_sale_modal"
                                            className={classes.dropdownLink}
                                          >
                                            Put on Sale
                                          </a>
                                        )}{" "}
                                        {item && item.status == 1 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#not_for_sale_modal"
                                            onClick={() => setsale(item)}
                                            className={classes.dropdownLink}
                                          >
                                            Not for Sale
                                          </a>
                                        )}{" "}
                                        {item && item.status === 1 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#change_price_modal"
                                            onClick={() => setsale(item)}
                                            className={classes.dropdownLink}
                                          >
                                            Change Price
                                          </a>
                                        )}
                                      </div>,
                                    ]}
                                  />
                                )}
                              </div>
                            )}
                            {item.userId === setacc[0] && item.status === 0 && (
                              <div className="productInfoTitle menu_dropdown">
                                {localStorage.getItem("ronergodinalklz") ===
                                  "yes" && (
                                  <CustomDropdown
                                    noLiPadding
                                    buttonText={
                                      <div>
                                        <Button
                                          className={
                                            classes.navLink + " create_btn"
                                          }
                                        >
                                          <i class="fas fa-ellipsis-h"></i>
                                        </Button>
                                      </div>
                                    }
                                    dropdownList={[
                                      <div className="noti_parent">
                                        {1 === 1 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#transfer_sale_modal"
                                            // onClick={() => transferRef.current.openModal()}
                                            className={classes.dropdownLink}
                                          >
                                            Transfer
                                          </a>
                                        )}
                                        {ownerDetails && ownerDetails.qty > 0 && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#burn_nft_modal"
                                            onClick={() => setsale(item)}
                                            className={classes.dropdownLink}
                                          >
                                            Burn NFT
                                          </a>
                                        )}
                                        {ownerDetails &&
                                          ownerDetails.status === 0 && (
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#put_sale_modal" 
                                              onClick={() => setsale(item)}
                                              className={classes.dropdownLink}
                                            >
                                              Put on Sale
                                            </a>
                                          )}{" "}
                                        {ownerDetails &&
                                          ownerDetails.status === 1 && (
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#not_for_sale_modal"
                                              onClick={() => setsale(item)}
                                              className={classes.dropdownLink}
                                            >
                                              Not for Sale
                                            </a>
                                          )}{" "}
                                        {ownerDetails &&
                                          ownerDetails.status === 1 && (
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#change_price_modal"
                                              onClick={() => setsale(item)}
                                              className={classes.dropdownLink}
                                            >
                                              Change Price
                                            </a>
                                          )}
                                      </div>,
                                    ]}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              <div className="tokenInfoList">
                <ul>
                  <li>
                    <h3>Rarity</h3>
                    <img
                      src={require("../../assets/images/icon_rarity.png")}
                      alt=""
                      className="img-fluid"
                    />
                    <h4>{singdataNft && singdataNft.rarity}</h4>
                    {/* <h5>+247</h5> */}
                  </li>
                  <li>
                    <h3>Affinity</h3>
                    <img
                      src={require("../../assets/images/icon_affinity.png")}
                      alt=""
                      className="img-fluid"
                    />
                    <h4>{singdataNft && singdataNft.affnity}</h4>
                    {/* <h5>+150</h5> */}
                  </li>
                  <li>
                    <h3>Attack</h3>
                    <img
                      src={require("../../assets/images/icon_attack.png")}
                      alt=""
                      className="img-fluid"
                    />
                    <h4>{singdataNft && singdataNft.attack}</h4>
                    {/* <h5>+299</h5> */}
                  </li>
                  <li>
                    <h3>Defence</h3>
                    <img
                      src={require("../../assets/images/icon_defence.png")}
                      alt=""
                      className="img-fluid"
                    />
                    <h4>{singdataNft && singdataNft.defence}</h4>
                    {/* <h5>+247</h5> */}
                  </li>
                  <li>
                    <h3>Health</h3>
                    <img
                      src={require("../../assets/images/icon_health.png")}
                      alt=""
                      className="img-fluid"
                    />
                    <h4>{singdataNft && singdataNft.health}</h4>
                  </li>
                </ul>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <ChangepRice saledata={notforsale} getNftdataID={getNftdataID} />
      <Burn saledata={notforsale} getNftdataID={getNftdataID} />
      <Transfer ref={transferRef} getNftdataID={getNftdataID} />
      <Putonsale saledata={notforsale} getNftdataID={getNftdataID} />
      <Notforsale saledata={notforsale} getNftdataID={getNftdataID} />

      <div
        class="modal fade primary_modal"
        id="checkout_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="checkout_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content" id="hide">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="checkout_modalLabel_1">
                Checkout
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => cancelfunction()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row mx-0 bor_bot_modal">
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Seller</p>
                  <p className="buy_title_md short_address">
                    {currentPurchase && currentPurchase.userId}
                  </p>
                </div>
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Buyer</p>
                  <p className="buy_title_md short_address">{setacc[0]}</p>
                </div>
              </div>
              <div className="bor_bot_modal mb-3">
                <p className="buy_title_md px-4 py-3 text-center mb-0 checkout_price">
                  {currentPurchase && currentPurchase.price}{" "}
                  {currentPurchase && currentPurchase.tokentype == 1
                    ? "BNB"
                    :currentPurchase && currentPurchase.tokentype == 2 ? "ZORO" : "ORO"}
                </p>
              </div>
              <div className="row mx-0 mb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm mb-0">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="textYellow mb-1">
                    {localStorage.getItem("balance")} BNB
                  </p>
                </div>
              </div>
              <div className="row mx-0 mb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm mb-0">Available Quantity</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  {currentPurchase && (
                    <p className="textYellow mb-1">{currentPurchase.qty}</p>
                  )}
                </div>
              </div>
              <div className="row mx-0 mb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm mb-0">Your Token Balance</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  {currentPurchase && currentPurchase.tokentype == 2?<p className="textYellow mb-1">{getBalanceofZOROToken} ZORO</p>:<p className="textYellow mb-1">{getBalanceofToken} ORO</p>}
                </div>
              </div>

              <div className="row mx-0 form-group formSkew new_form">
                <div className="col-12 col-sm-6 p-0 mt-3">
                  <p className="buy_desc_sm mb-0">Enter Quantity</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <input
                    type="text"
                    id="quantity"
                    name="quantity"
                    className="form-control"
                    onKeyUp={onKeyUp}
                    value={quantity}
                    onChange={changeQuan}
                  />
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm mb-0">
                    Service fee added to the amount
                  </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="textYellow mb-1">
                    {currentPurchase && currentPurchase.tokentype == 2?0:servicefee} %{" "}
                    {currentPurchase && currentPurchase.tokentype == 1
                      ? " BNB"
                      :currentPurchase && currentPurchase.tokentype == 2 ? "ZORO" : "ORO"}
                  </p>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm mb-0">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="textYellow mb-1">
                    {youNeedtoPay.toFixed(8)}{" "}
                    {currentPurchase && currentPurchase.tokentype == 1
                      ? " BNB"
                      : currentPurchase && currentPurchase.tokentype == 2 ? "ZORO" : "ORO"}
                  </p>
                </div>
              </div>
              <div className="noteTextMessage">
                Don't refresh the page when loading
              </div>
              <div className="text-center my-3">
                <Button
                  className={classes.navLink + " create_btn btn-block mb-4"}
                  onClick={() => {
                    buyNow();
                  }}
                  disabled={submitButton ? "true" : ""}
                >
                  {submitButton && <i class="fas fa-spinner fa-spin mr-2"></i>}
                  Proceed to Payment
                </Button>
                <Button
                  className={classes.navLink + " create_btn_black btn-block"}
                  data-dismiss="modal"
                  onClick={() => cancelfunction()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
