import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ConnectWallet from "./separate/Connect-Wallet";
import TokenItem from "./separate/tokenlist";
import DataTable from "react-data-table-component";
import Web3 from "web3";
import "@metamask/legacy-web3";
import ABI from "../ABI/ABI.json";
import config from "../config/config";
import { getCurAddr, getmyeventlist , getsaleTokenstop , getownTokenstop,getTokenstop,getHotTokenstop} from "../actions/users";
import { toast } from "react-toastify";
import loaderimage from "../assets/images/loading.gif";
import imageloader  from "../assets/images/imgload.gif";
toast.configure();
let toasterOption = config.toasterOption;
var mynetwork = config.NETWORKVERSION;

const dashboardRoutes = [];

// Trade History Table
const table_data = [];

const table_columns = [
  {
    name: "Token Name",
    selector: "tokenName",
  },
  {
    name: "Date",
    selector: "date",
  },
  {
    name: "To wallet address",
    selector: "toAddress",
    width: "400px",
  },
  {
    name: "qty",
    selector: "qty",
  },
  {
    name: "selling price",
    selector: "sellingPrice",
  },
];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Myitems(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const [myeventlist, setmyeventlist] = useState([]);
  const [items, Set_item] = useState({});
  const [selecteditem, set_selecteditem] = React.useState({});
  const [amount, setamount] = useState({});
  const [toaddress, settoaddress] = useState({});
  const [onprogress, setonprogress] = React.useState(false);
  const [ownTokenList, setownTokenList] = React.useState([]);
  const [ownTokenListstatus, setownTokenListstatus] = React.useState(true);
  const [saleTokenList, setsaleTokenList] = React.useState([]);
  const [saleTokenListstatus, setsaleTokenListstatus] = React.useState(true); 
  const [filtertype,setfiltertype] = React.useState("")
  const [selectedoption,setselectedoption] = React.useState("recent");
  const [Keyword, Set_Keyword] = React.useState('');
  const [owntokenlimit, setowntokenlimit] = React.useState(9);
  const [saletokenlimit, setsaletokenlimit] = React.useState(9);
  const adrs =localStorage.getItem("address");
  var loading =[];

  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr == localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          await getsalelist(curAddr);
          await getownlist(curAddr);
        } else {
          localStorage.clear();
          window.location.href = "/home";
        }
      }
    } catch (err) {}
  }

    useEffect(()=>{
      init();
    },[])

    const init =async()=>{
      let curAddr = await getCurAddr();
      getsalelist(curAddr);
      getownlist(curAddr)
    }

    const  filterchange = async (e) =>{
      setfiltertype(e.target.value)
      var data={
        "filter":e.target.value,
        "search":Keyword,
        address : localStorage.getItem("address"),
      }
      console.log(data,selectedoption,"datadatadata")
      if(selectedoption=="recent"){
        var tokenList = await getsaleTokenstop(data);
        if(tokenList && tokenList.result && tokenList.result.data){
         setsaleTokenList(tokenList.result.data);
         setsaleTokenListstatus(false);
       }
      }else{
        var ownList = await getownTokenstop(data);
        if(ownList && ownList.result && ownList.result.data){
          setownTokenList(ownList.result.data);
          setownTokenListstatus(false);
        }
      }
    }

  const searchChange = async (e) => {
    var value = e.target.value;
    console.log(value,"valuevaluevaluevalue");
    // Set_Keyword(value)
    if (value && value != "") {
        Set_Keyword(value);
         var data={
            "filter":filtertype,
            "search":value,
            address :localStorage.getItem("address"),
          }
          console.log(selectedoption,"selectedoptionselectedoptionselectedoption")
          if(selectedoption=="recent"){
            var tokenList = await getsaleTokenstop(data);
            console.log(tokenList,'tokenLL');
            if(tokenList && tokenList.result && tokenList.result.data){
             setsaleTokenList(tokenList.result.data);
             setsaleTokenListstatus(false)
           }
          // getsalelist(data)
          }else{
            var ownList = await getownTokenstop(data);
            if(ownList && ownList.result && ownList.result.data) {
              setownTokenList(ownList.result.data);
              setownTokenListstatus(false);
            }
            // getownlist(data)
          }
    }else{
        Set_Keyword("");
        // getTokensList();
        // getHotcollectionlist();
        getsalelist(localStorage.getItem("address"));
        getownlist(localStorage.getItem("address"))
    }
  }


  async function getsalelist(addd){
    var data={
      address :  addd,
      limit : saletokenlimit
    }
    var tokenList = await getsaleTokenstop(data);
    console.log(tokenList,'ff');
    if(tokenList && tokenList.result && tokenList.result.data){
     setsaleTokenList(tokenList.result.data);
     setsaleTokenListstatus(false)
   }
  }

  const loadgetsalelist= async(event)=>{
    let address = localStorage.getItem("address");
    let limit = saletokenlimit + 6 ;
    setsaletokenlimit(limit)
    var data={
      address :  address,
      limit: limit
    }
    var tokenList = await getsaleTokenstop(data);
    console.log(tokenList,'ff');
    if(tokenList && tokenList.result && tokenList.result.data){
     setsaleTokenList(tokenList.result.data);
     setsaleTokenListstatus(false)
   }
    }

  async function getownlist(addd){
    var data={
      address :  addd,
      limit: owntokenlimit
    }
    var ownList = await getownTokenstop(data);
    if(ownList && ownList.result && ownList.result.data){
     setownTokenList(ownList.result.data);
     setownTokenListstatus(false);
    }
 

  }

  const loadgetownlist= async(event)=>{
  let address = localStorage.getItem("address");
  let limit = owntokenlimit + 6 ;
  setowntokenlimit(limit)
  var data={
    address :  address,
    limit: limit
  }
  var ownList = await getownTokenstop(data);
  if(ownList && ownList.result && ownList.result.data){
   setownTokenList(ownList.result.data);
   setownTokenListstatus(false);
  }
  }

  async function get_mynftlist(addrr) {
    var data = {
      myaddress: addrr,
    };
    var mylist = await getmyeventlist(data);
    if (mylist && mylist.result && mylist.result.data) {
      setmyeventlist(mylist.result.data);
    }
  }



  async function recentclick(){
    getsalelist(localStorage.getItem("address"));
    setselectedoption("recent")
    Set_Keyword("")
    setfiltertype("")
  }

  async function ownclick(){
    getownlist(localStorage.getItem("address"));
    setselectedoption("own")
    Set_Keyword("")
    setfiltertype("")
  }

  async function safetransfer() {
    setonprogress(true);
    if (
      amount &&
      amount != null &&
      amount != undefined &&
      amount != "" &&
      toaddress &&
      toaddress != null &&
      toaddress != undefined &&
      toaddress != "" &&
      parseFloat(amount) > 0 &&
      selecteditem
    ) {
      if (parseInt(amount) <= parseInt(selecteditem.value)) {
        var web3 = new Web3(window.ethereum);
        var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
        var curAddr = await getCurAddr();
        var ptamt = amount.toString();
        await Contract.methods
          .safeTransferFrom(
            curAddr,
            toaddress,
            selecteditem.tokenid,
            ptamt.toString(),
            "0x"
          )
          .send({ from: curAddr })
          .then(async (result) => {
            console.log("result", result);
            toast.warning("Transferred Successfully", toasterOption);
            window.location.reload();
          })
          .catch((err) => {
            setonprogress(false);
            console.log("err", err);
          });
      } else {
        setonprogress(false);
        toast.warning("Insufficient Quantity", toasterOption);
      }
    } else {
      setonprogress(false);
      console.log(
        amount,
        amount != null,
        amount != undefined,
        amount != "" && toaddress,
        toaddress != null,
        toaddress != undefined,
        toaddress != "",
        parseFloat(amount) > 0,
        selecteditem
      );
      toast.warning("Please Enter Valid Input", toasterOption);
    }
  }

  const amountchange = (e) => {
    setamount(e.target.value);
  };

  const toaddresschange = (e) => {
    settoaddress(e.target.value);
  };

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img
              src={require("../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">MY ITEMS</h2>
              </div>
            </GridItem>
          </GridContainer>
          <div className="exploreFilter">
            <div className="filterLeft">
              <nav>
                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link create_btn active"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    <span onClick={recentclick}>On Sale</span>
                  </a>
                  <a
                    class="nav-item nav-link create_btn"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    <span onClick={ownclick}>Owned</span>
                  </a>
                </div>
              </nav>
            </div>

            <div className="search_inp_group">
                <input type="text" value={Keyword} onChange={searchChange} className="search_inp" id="searchinput" placeholder="Search item" />
                <div className="search_inp_group_append">
                    <i className="fas fa-search"></i>
                </div>
            </div>

            <div className="filterRight">
              <div className="form-group mb-0">
                <label className="mr-2">Sort</label>
                <Select
                  className={classes.selectEmpty + " selectSkew w-100"}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={filterchange}
                >
                  {/* <MenuItem value="recent" >Recently Listed</MenuItem> */}
                  <MenuItem value="recent" >Recently Listed</MenuItem>
                  <MenuItem value="oldest">Oldest Listed</MenuItem>
                 
                  <MenuItem value="high" >Price High</MenuItem>
                  <MenuItem value="low" >Price Low</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div class="tab-content pt-4" id="nav-tabContent" >
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              
              {saleTokenListstatus &&
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div className="itemCard">
                  <img src={imageloader} alt="Collections" />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <div className="itemCard">
            <img src={imageloader} alt="Collections" />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <div className="itemCard">
            <img src={imageloader} alt="Collections" />
            </div>
          </GridItem>
          </GridContainer >
                }
             <GridContainer>  
              {saleTokenList &&
               saleTokenList.length > 0 &&
               saleTokenList.map((item) => {
                return(
                <GridItem xs={12} sm={12} md={4}>
                
                <Link to={"/product-info/" + item._id}>
                  <div className="itemCard">
                    <div className="itemImage">
                    {
                            item.tokenName === "mp4" ?
                            <>
                              <img src={require("../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                              </>
                              :
                              item.tokenName === "mp3" ?
                                <>
                                  <img src={require("../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                  {/* <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" >
                                    </audio> */}
                                </>
                                :
                                <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                          }
                      {/* <img
                        src={item.imageDataUrl}
                        className="img-fluid"
                        alt=""
                      /> */}
                    </div>
                    <div className="itemDetails">
                      <h3>
                        {item.name}<br></br> <small>#{item.tokenId}</small>
                      </h3>
                      <p>
                        From:{" "}
                        <span>
                          {item.userId}
                        </span>
                      </p>
                      <h4 className="itemPriceCrypto">
                        <img
                          src={require("../assets/icon/favicon-32x32.png")}
                          className="img-fluid logo_img"
                          alt="Icon"
                        />{" "}
                        {item.price}{" "}
                        {item.tokentype == 1 ? " / BNB" :item.tokentype == 2 ?" / ZORO": " / ORO"}
                      </h4>
                      {/*<h4 className="itemPriceFiat">
                        <img
                          src={require("../assets/icon/favicon-32x32.png")}
                          className="img-fluid logo_img"
                          alt="Icon"
                        />{" "}
                        348.5 USD
                      </h4>*/}
                      <h6>
                          <Link to={"/product-info/" + item._id}>                          
                          <img
                            src={require("../assets/images/itemArrow.png")}
                            className="img-fluid"
                            alt=""
                          />
                        </Link>
                      </h6>
                    </div>
                  </div>
                  </Link>
                </GridItem>
                )
              })}
              </GridContainer>
              {saleTokenList &&
               saleTokenList.length > 0 &&<Button onClick={loadgetsalelist} className={classes.navLink + " create_btn px-4"}>
                        Load More
              </Button>}
            </div>
            
            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
                {ownTokenListstatus&&
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="itemCard">
                    <img src={imageloader} alt="Collections" />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                  <div className="itemCard">
                  <img src={imageloader} alt="Collections" />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
            </GridItem>
            </GridContainer>
                }
              <GridContainer>
              {ownTokenList &&
               ownTokenList.length > 0 &&
               ownTokenList.map((item,key) => {
                return(
                <GridItem xs={12} sm={12} md={4}>
                  <a href={item.qty>0?("/product-info/" + item._id):"javascript:void(0)"}>
                  {/* <a href={("/product-info/" + item._id)}> */}
                   
                  <div className="itemCard">
                    <div className="itemImage">
                    {
                            item.tokenName === "mp4" ?
                            <>
                              <img src={require("../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                              </>
                              :
                              item.tokenName === "mp3"?
                                <>
                                  <img src={require("../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                
                                </>
                                :
                                <>{
                                <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                                 }</>
                          }
                    </div>
                    <div className="itemDetails">
                      <h3>
                        {item.name}<br></br> <small>#{item.tokenId}</small>
                      </h3>
                      <p>
                        From:{" "}
                        <span>
                          {item.userId}
                        </span>
                      </p>
                      <h4 className="itemPriceCrypto">
                        <img
                          src={require("../assets/icon/favicon-32x32.png")}
                          className="img-fluid logo_img"
                          alt="Icon"
                        />{" "}
                        {item.price}{" "}
                        {item.tokentype == 1 ? " / BNB" :item.tokentype == 2 ?" / ZORO": " / ORO"}
                      </h4>
                      <h6>
                          <img
                            src={require("../assets/images/itemArrow.png")}
                            className="img-fluid"
                            alt=""
                          />
                        {/* </Link> */}
                      </h6>
                    </div>
                  </div>
                  </a>
                </GridItem>
                
                )
              })}
              </GridContainer>
            {ownTokenList &&
               ownTokenList.length > 0 &&<Button onClick={loadgetownlist} className={classes.navLink + " create_btn px-4"}>
                        Load More
              </Button>}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* edit_cover Modal */}
      <div
        class="modal fade primary_modal"
        id="edit_cover_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="edit_cover_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="edit_cover_modalLabel_1">
                Update cover
              </h5>
              <h5 class="modal-title d-none" id="edit_cover_modalLabel_2">
                Follow Steps
              </h5>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">
                  Upload new cover for your profile page. We recommended to
                  upload images in 1140×260 resolution
                </p>
                <form className="text-center">
                  <div className="file_btn btn primary_btn">
                    Choose image
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </form>
              </div>
              <div
                className="update_cover_div_2 d-none"
                id="update_cover_div_2"
              >
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i
                    class="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Inprogress
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}

      {/* Put Sale Modal */}
      <div
        class="modal fade primary_modal"
        id="put_sale_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="put_sale_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content" id="hide">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="put_sale_modalLabel_1">
                Put on Sale
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <form>
                  <div className="form-group formSkew">
                    <div className="input-group">
                      <input type="text" name="" className="form-control" />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">
                          <Select
                            displayEmpty
                            className={classes.selectEmpty + " selectSkew"}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>BNB</MenuItem>
                            <MenuItem value={20}>ETH</MenuItem>
                            <MenuItem value={30}>TRX</MenuItem>
                          </Select>
                        </span>
                      </div>
                    </div>
                  </div>
                  <p class="form_note text-grey mb-0">
                    Service fee <span className="textYellow">2.5%</span>
                  </p>
                  <p class="form_note text-grey mb-0">
                    You will receive <span className="text-white">0 ETH</span>{" "}
                    <span className="textYellow">$0.00</span>
                  </p>
                  <div className="text-center my-3">
                    <Button
                      className={classes.navLink + " create_btn btn-block mb-4"}
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#follow_steps_modal"
                    >
                      Next Step
                    </Button>
                    <Button
                      className={
                        classes.navLink + " create_btn_black btn-block"
                      }
                      data-dismiss="modal"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Put Sale modal */}

      {/* Transfer Sale Modal */}
      <div
        class="modal fade primary_modal"
        id="transfer_sale_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="put_sale_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content" id="hide">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="put_sale_modalLabel_1">
                Transfer Token
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <form>
                  <div className="form-group formSkew">
                    <div className="input-group">
                      <input
                        type="text"
                        name=""
                        placeholder="To Addresss"
                        onChange={toaddresschange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group formSkew">
                    <div className="input-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Quantity"
                        onChange={amountchange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="text-center my-3">
                    {onprogress ? (
                      <Button
                        className={
                          classes.navLink + " create_btn btn-block mb-4"
                        }
                      >
                        Processing.....!
                      </Button>
                    ) : (
                      <Button
                        className={
                          classes.navLink + " create_btn btn-block mb-4"
                        }
                        onClick={() => safetransfer()}
                      >
                        Transfer
                      </Button>
                    )}
                    <Button
                      className={
                        classes.navLink + " create_btn_black btn-block"
                      }
                      data-dismiss="modal"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Transfer Sale modal */}

      {/* Following Steps Modal */}
      <div
        class="modal fade primary_modal"
        id="follow_steps_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="follow_steps_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content" id="hide">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="follow_steps_modalLabel_1">
                Follow Steps
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i
                    class="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign Sell Order</p>
                    <p className="mt-0 approve_desc">
                      Sign sell order using your wallet
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Follow Wallet Instructions
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Following Steps modal */}
    </div>
  );
}
