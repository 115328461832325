import React, { useEffect, useState, useRef } from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect, useLocation } from "react-router-dom";
import Web3 from "web3";
import "@metamask/legacy-web3";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import $ from "jquery";
import axios from "axios";
import Modal from "react-modal";
import config from "../../config/config";
import { toast } from "react-toastify";
import { storeAddressinDb,checkwhiteaddress,getCurAddr } from "../../actions/users";
import {CloseOutlined} from "@material-ui/icons";

toast.configure();
let toasterOption = config.toasterOption;
const useStyles = makeStyles(styles);
var mynetwork = config.NETWORKVERSION;

export default function ConnectWallet(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { WalletConnected } = props;
  // const history = useHistory();
  const [createshow,setcreateshow] = useState(true)
  const timerRef = useRef(null);

  useEffect(() => {
    getInit();
    getUserData();
  }, []);

  async function getInit() {
    connect_Wallet("metamask");
  }

  let web3;

  async function connect_Wallet(type = "metamask") {
    window.$(".modal").modal("hide");
    if (
      localStorage.getItem("ronergodinalklz") &&
      localStorage.getItem("ronergodinalklz") !== null &&
      localStorage.getItem("ronergodinalklz") !== undefined &&
      localStorage.getItem("ronergodinalklz") !== ""
    ) {
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (typeof web3 !== "undefined") {
      } else {
        props.Set_WalletConnected(false);
        toast.warning("Please Add Metamask External", toasterOption);
        return false;
      }

      if (provider) {
        try {
          provider
            .enable()
            .then(async function () {
              const web3 = new Web3(window.web3.currentProvider);
              var currnetwork = await web3.eth.net.getId();
              console.log(currnetwork,"currnetwork");
              if (currnetwork == mynetwork) {
                if (window.web3.currentProvider.isMetaMask === true) {
                  if (
                    window.web3 &&
                    window.web3.eth &&
                    window.web3.eth.defaultAccount
                  ) {
                    props.Set_WalletConnected(true);
                    var result = await web3.eth.getAccounts();
                    var setacc = result[0];
                    localStorage.setItem("ronergodinalklz", "yes");
                    localStorage.setItem("address", result[0]);
                    props.Set_Accounts(setacc);
                    props.Set_UserAccountAddr(setacc);
                    web3.eth.getBalance(setacc).then(async (val) => {
                      var balance = val / 1000000000000000000;
                      props.Set_UserAccountBal(balance);
                      localStorage.setItem("balance", balance);

                      var data = {
                        myaddress: setacc,
                      };

                      var mylist = await storeAddressinDb(data);
                      AfterWalletConnected();
                    });
                  }
                }
              } else {
                props.Set_WalletConnected(false);
                toast.warning("Please Connect to BNB Network", toasterOption);
              }
            })
            .catch((e) => {
              props.Set_WalletConnected(false);
              toast.warning("Please Connect to BNB Network", toasterOption);
            });
        } catch (err) {
          props.Set_WalletConnected(false);
        }
      } else {
        props.Set_WalletConnected(false);
        toast.warning("Please Add Metamask External", toasterOption);
      }
    }else{
      if(location_pathname == "create-single"){
        window.location.href = "/home";
      }
    }
  }

  async function getUserData(){
    if (localStorage.getItem("ronergodinalklz")) {
      const web3 = new Web3(window.web3.currentProvider);
      var setacc = await  web3.eth.getAccounts()
      var curAddr = setacc[0];
      var data={
        "address" : curAddr
      }
      var test = await checkwhiteaddress(data);
      if(test && test.data && test.data.data && test.data.data.length>0){
        setcreateshow(true)
      }else{
        setcreateshow(false)
      }
    }
  }

  async function AfterWalletConnected() {
    props.AfterWalletConnected();
  }

  window.addEventListener("load", (event) => {
    event.preventDefault();
    console.log("addEventListener", event);
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        window.location.reload(false);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          connect_Wallet();
        }, 1000);
      });

      window.ethereum.on("networkChanged", function (networkId) {
        if (networkId == mynetwork) {
          window.location.reload(false);
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            connect_Wallet();
          }, 1000);
          props.Set_WalletConnected(true);
        } else {
          props.Set_WalletConnected(false);
        }
      });
    }
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  let subtitle;
  const [
    WalletConnectNotifyPopup,
    Set_WalletConnectNotifyPopup,
  ] = React.useState(false);

  function openModal() {
    Set_WalletConnectNotifyPopup(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    Set_WalletConnectNotifyPopup(false);
  }

  var WalletConnectNotifyPopup_test = "";

  if (WalletConnected) {
    WalletConnectNotifyPopup_test = false;
  } else {
    WalletConnectNotifyPopup_test = true;
  }

  var pathVal = "";

  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split("/").length >= 2) {
      pathVal = location.pathname.split("/")[1];
    }
  }

  const [location_pathname, Set_location_pathname] = useState(pathVal);

  const connect_Wallet_call = (type) => {
    if (WalletConnected !== true) {
      connect_Wallet(type);
    }
  };

  
  return (
    <div>
      {props.fullLoader && <div class="loading"></div>}
      {(WalletConnected === false || WalletConnected === "false") &&
        (location_pathname === "my-items" ||
          location_pathname === "edit-profile" ||
          location_pathname === "activity" ||
          location_pathname === "support-ticket"||
          location_pathname === "product-info") && (
          <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          ><h1> Please Connect Wallet</h1>
          <center><h5><Link to='/home'>Home</Link></h5></center></Modal>
        )}
      <div
        id="connect_Wallet_call"
        onClick={() => connect_Wallet_call("metamask")}
      ></div>
     {(createshow!==true) &&
        (location_pathname == "create-single") && (
          <Redirect to={"/home"}></Redirect>
        )}
    </div>
  );
}
