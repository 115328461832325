let key = {};
if (process.env.NODE_ENV === "production") {
  // *********************************************demo**********************************
  // const FRONT_URL = "http://44.207.223.46/";
  // const Back_URL = "http://44.207.223.46:2053/";
  // const IPFS_IMG = "https://operonorigins.mypinata.cloud/ipfs";
  // const NFTCONTRACT = "0xE9B210c5eB9864Eaf77124e8417249b11dF57a5b";
  // const NFTCONTRACTMINT = "0x344ae9fCBf60e429ae9D4b66A62443BC8aC8C11c";
  // const ORE = "0x49443c7fE76c622bA9eb25C3Cc2cCa104d8de87c";
  // const ZORO = "0x3f648D009e80c801877755017795Ac7469CD9B01";
  // const NETWORKVERSION = 97;

  // key = {
  //   baseurl: Back_URL,
  //   fronturl: FRONT_URL,
  //   IPFS_IMG: IPFS_IMG,
  //   NFTCONTRACT: NFTCONTRACT,
  //   ORE: ORE,
  //   NETWORKVERSION: NETWORKVERSION,
  //   NFTCONTRACTMINT: NFTCONTRACTMINT,
  //   ZORO:ZORO,
  //   decimalValues: 1000000000000000000,
  //   fees: 0.2,
  // };

  //***********************************LIVE**************************************//
  const FRONT_URL = "https://marketplace.operonorigins.com/";
  const Back_URL = "https://marketapi.operonorigins.com/";
  const IPFS_IMG = "https://operonorigins.mypinata.cloud/ipfs";
  const NFTCONTRACT = "0xb3bC621E236D086c430dA7E6717568F30971A717";
  const NFTCONTRACTMINT = "0xB5423518E53fB17e268391C4673D24327cb82b03";
  const ORE = "0xfc4f5a4d1452b8dc6c3cb745db15b29c00812b19";
    const ZORO = "0xc9537fDD4982A483E59AE69889e65C719b1052E3";

  const NETWORKVERSION = 56;

  key = {
    baseurl: Back_URL,
    fronturl: FRONT_URL,
    IPFS_IMG: IPFS_IMG,
    ORE: ORE,
    ZORO: ZORO,
    NFTCONTRACT: NFTCONTRACT,
    NETWORKVERSION: NETWORKVERSION,
    NFTCONTRACTMINT: NFTCONTRACTMINT,
    decimalValues: 1000000000000000000,
    fees: 0.2,
  };
} else {
  const FRONT_URL = "http://localhost:3000/";
  const Back_URL = "http://localhost:2053/";
  const IPFS_IMG = "https://operonorigins.mypinata.cloud/ipfs";
  const NFTCONTRACT = "0xE9B210c5eB9864Eaf77124e8417249b11dF57a5b";
  const NFTCONTRACTMINT = "0x344ae9fCBf60e429ae9D4b66A62443BC8aC8C11c";
  const ORE = "0x49443c7fE76c622bA9eb25C3Cc2cCa104d8de87c";
  const ZORO = "0x3f648D009e80c801877755017795Ac7469CD9B01";
  const NETWORKVERSION = 97;

  key = {
    baseurl: Back_URL,
    fronturl: FRONT_URL,
    IPFS_IMG: IPFS_IMG,
    NFTCONTRACT: NFTCONTRACT,
    ORE: ORE,
    ZORO: ZORO,
    NETWORKVERSION: NETWORKVERSION,
    NFTCONTRACTMINT: NFTCONTRACTMINT,
    decimalValues: 1000000000000000000,
    fees: 0.2,
  };
}
export default key;
