// import lib
import isEmpty from './isEmpty';

const validation = value => {
    console.log('validatation',value)
    let errors = {};
    let AlphabetsRegex = /^[A-Za-z ]+$/;
    let NumbersOnlyRegex =/^[0-9\b]+$/;
    if (isEmpty(value.name)) {
        errors.name = "Please enter Name"
    } else if (!(AlphabetsRegex.test(value.name))) {
        errors.name = "Please enter Alphabets Only"
    }


    if (isEmpty(value.qty)) {
        errors.qty = "Please enter Quantity"
    }
    if (isEmpty(value.rarity)) {
        errors.rarity = "Please enter Rarity"
    }else if (!(AlphabetsRegex.test(value.rarity))) {
        errors.rarity = "Please enter Alphabets Only"
    }
    if (isEmpty(value.affnity)) {
        errors.affnity = "Please enter Affinity"
    }else if (!(AlphabetsRegex.test(value.affnity))) {
        errors.affnity = "Please enter Alphabets Only"
    }
    if (isEmpty(value.attack)) {
        errors.attack = "Please enter Attack"
    }else if (!(NumbersOnlyRegex.test(value.attack))) {
        errors.attack = "Please enter Numbers Only"
    }
    if (isEmpty(value.defence)) {
        errors.defence = "Please enter Defence"
    }else if (!(NumbersOnlyRegex.test(value.defence))) {
        errors.defence = "Please enter Numbers Only"
    }
    if (isEmpty(value.health)) {
        errors.health = "Please enter Health"
    }else if (!(NumbersOnlyRegex.test(value.health))) {
        errors.health = "Please enter Numbers Only"
    }
    if (isEmpty(value.TokenFileType)) {
        errors.file = "Please select file"
    }
    return errors;
}

export default validation;