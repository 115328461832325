import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import styles from "./../../assets/jss/material-kit-react/views/landingPage.js";
import "@metamask/legacy-web3";
import ABI from "../../ABI/ABI.json";
import config from "../../config/config";
import { toast } from "react-toastify";
import Web3 from "web3";
import { updateburn } from "../../actions/users";

toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);

export default function Notforsale(props) {
  const classes = useStyles();

  const { getNftdataID, saledata } = props;

  const [youNeedtoBurn, setyouNeedtoBurn] = useState();
  const [submitButton, setsubmitButton] = React.useState("");
  const cancelfunction = () => {
    setyouNeedtoBurn("");
  };

  const changeQuan = (quan) => {
    setyouNeedtoBurn(quan.target.value);
  };

  async function burnNow() {
    if (youNeedtoBurn <= 0 || youNeedtoBurn === undefined) {
      toast.error("Please Enter Valid Quantity", toasterOption);
      return false;
    }

    if (saledata.qty < youNeedtoBurn) {
      toast.error("Insufficient Quantity", toasterOption);
      return false;
    }
    setsubmitButton(1);
    var web3 = new Web3(window.ethereum);
    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);

    var setacc = await web3.eth.getAccounts();
    
    Contract.methods
      .burnToken(
        setacc[0],
        saledata.tokenId,
        youNeedtoBurn,
        config.NFTCONTRACTMINT,
        saledata.offeringID !== "0" &&
          saledata.offeringID !== "" &&
          saledata.offeringID !== 0
          ? saledata.offeringID
          : "0x",
        saledata.offeringID !== "0" &&
          saledata.offeringID !== "" &&
          saledata.offeringID !== 0
          ? 1
          : 0
      )
      .send({ from: setacc[0] })
      .then(async (result) => {
        var reqData = {
          id: saledata._id,
          qty: youNeedtoBurn,
        };

        await updateburn(reqData);
        window.location.href = config.fronturl + "my-items";
        await getNftdataID();
        setTimeout(
          () => (window.location.href = config.fronturl + "my-items"),
          1200
        );

        toast.success("Successfully Burned", toasterOption);
      })
      .catch((error) => {
        toast.error("User cancelled Burned", toasterOption);
        setsubmitButton("");
      });
  }
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
    } else {
      setyouNeedtoBurn("");
    }
  };
  return (
    <div
      class="modal fade primary_modal"
      id="burn_nft_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="burn_nft_modalCenteredLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" id="hide">
          <div class="modal-header text-center">
            <h5 class="modal-title" id="burn_nft_modalLabel_1">
              Burn NFT
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => cancelfunction()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="update_cover_div_2" id="update_cover_div_2">
              <form>
                <div className="form-group formSkew">
                  <div className="input-group">
                    <input
                      type="number"
                      name=""
                      value={youNeedtoBurn}
                      placeholder="Quantity"
                      className="form-control"
                      onKeyUp={onKeyUp}
                      onChange={(e) => {
                        changeQuan(e);
                      }}
                    />
                  </div>
                </div>
                <div className="noteTextMessage">
                  Don't refresh the page when loading
                </div>
                <div className="text-center my-3">
                  <Button
                    className={classes.navLink + " create_btn btn-block mb-4"}
                    onClick={burnNow}
                    disabled={submitButton ? "true" : ""}
                  >
                    {submitButton && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                    Burn NFT
                  </Button>
                  <Button
                    className={classes.navLink + " create_btn_black btn-block"}
                    data-dismiss="modal"
                    onClick={() => cancelfunction()}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
