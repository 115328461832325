import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import Web3 from "web3";
import "@metamask/legacy-web3";
import config from "../config/config";
import { toast } from "react-toastify";
import { storeAddressinDb } from "../actions/users";

toast.configure();
let toasterOption = config.toasterOption;
const useStyles = makeStyles(styles);
var mynetwork = config.NETWORKVERSION;
const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // localStorage.setItem("disconnect",false)
  }, []);
  return null;
}

export default function ConnectWallet(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [accounts, setAccounts] = React.useState();

console.log(mynetwork,"mynetwork");


  async function connectMetamask() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            console.log(window.web3.currentProvider.networkVersion,"version");

            var currnetwork = await web3.eth.net.getId();
            console.log(currnetwork,"currnetwork");
            
            if (currnetwork == mynetwork) {
              if (window.web3.currentProvider.isMetaMask === true) {
                await web3.eth.getAccounts(async function (error, result) {
                  setAccounts(result[0]);
                  localStorage.setItem("ronergodinalklz", "yes");
                  localStorage.setItem("address", result[0]);
                  localStorage.setItem("address", result[0]);
                  var setacc = result[0];

                  web3.eth.getBalance(setacc).then(async (val) => {
                    var balance = val / 1000000000000000000;
                    localStorage.setItem("balance", balance);
                  });

                  var data = {
                    myaddress: setacc,
                  };

                  var mylist = await storeAddressinDb(data);
                  window.location.href = "/home";
                });
              }
            } else {
              toast.warning("Please Connect to BNB Network", toasterOption);
            }
          });
        } else {
          toast.warning("Please Add Metamask External", toasterOption);
        }
      } catch (err) {}
    } else {
      toast.warning("Please Add Metamask External", toasterOption);
    }
  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img
              src={require("../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">Connect your wallet</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="create_para mt-3 text-center text-white">
                We do not own your private keys and cannot access your funds
                without your confirmation.
              </p>
              <div
                className="create_box_panel connect_row"
                onClick={() => connectMetamask()}
              >
                <div className="w-50 mx-auto">
                  <Link className="create_box connect_wallet_box">
                    <h2>Metamask</h2>
                    <img
                      src={require("../assets/images/metamask.svg")}
                      alt="logo"
                      className="img-fluid mt-3"
                    />
                    <p className="mb-0 text-center mt-3">
                      One of the most secure wallets with great flexibility
                    </p>
                  </Link>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
