import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import ConnectWallet from "./separate/Connect-Wallet";
import  { zeroPad } from "react-countdown";
import "@metamask/legacy-web3";
import { getCurAddr, getmyeventlist, getTokenstop, getHotTokenstop, getepicdata } from "../actions/users";
import imageloader  from "../assets/images/imgload.gif";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Home(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const [myeventlist, setmyeventlist] = useState([]);
  const [items, Set_item] = useState({});
  const [HotTokenList,setHotTokenList] = useState({});
  const [HotTokenListstatus,setHotTokenListstatus] = useState(true);
  const [tokenListstatus, setTokenListstatus] = React.useState(true);
  const [tokenList, setTokenList] = React.useState([]);
  const [epic, setepic] = React.useState({});

  async function getTokensList() {
    var tokenList = await getTokenstop();
    setTokenList(tokenList.result.data);
    setTokenListstatus(false);
  }

  async function getHotcollectionlist() {
    var hottokenList = await getHotTokenstop();
    setHotTokenList(hottokenList.result.data);
    setHotTokenListstatus(false)
  }

  async function getepicdatas() {
    var epics = await getepicdata();
    console.log(epics.result,'epics')
    setepic(epics.result)
  }

  useEffect(() => {
    getTokensList();
    getHotcollectionlist();
    getepicdatas()
  }, []);

  // Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">d</span>
        </span>
        <span className="timer_dots"> </span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">h</span>
        </span>
        <span className="timer_dots"> </span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">m</span>
        </span>
        <span className="timer_dots"> </span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">s</span>
        </span>
      </div>
    );
  };

  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr == localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          await get_mynftlist(curAddr);
        } else {
          localStorage.clear();
          window.location.reaload();
        }
      }
    } catch (err) {}
  }

  async function get_mynftlist(addrr) {
    var data = {
      myaddress: addrr,
    };
    var mylist = await getmyeventlist(data);
    if (mylist && mylist.result && mylist.result.data) {
      setmyeventlist(mylist.result.data);
    }
  }
console.log(epic,"epicsss")
  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <img
            src={require("../assets/images/logo.png")}
            alt="logo"
            className="img-fluid"
          />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />

      <div className="homeMain">
        <section className="homeBanner">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <div className="homeBannerContent">
                  <h1>Collect & sell exclusive NFT's</h1>
                  <h6>
                    Digital marketplace for crypto collectibles and non-fungible
                    tokens(NFTs). Discover world of exclusive digital items.
                  </h6>
                  <div className="form-group mb-0 mt-2">
                    <Link to="/explore" className="text-center d-block mt-4">
                      <Button className={classes.navLink + " create_btn px-4"}>
                        Explore Now
                      </Button>
                    </Link>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className="homeBannerImg">
                  <img
                    src={require("../assets/images/avathar1.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </section>
        <section className="homeStatistics">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <div className="homeStatisticsContent">
                  <div className="d-flex align-items-center justify-content-center">
                    <h2 className="inner_title">Most Epic Stats Ever</h2>
                  </div>
                  <div className="circleStatistics">
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <div className="circleCard">
                         {epic?<h2>{epic.TotalCount + 200}</h2>:<h2>0</h2>}
                          <h4>
                            Total
                            <br />
                            Counts
                          </h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <div className="circleCard">
                        {epic?<h2>{epic.volume + 200}</h2>:<h2>0</h2>}
                          <h4>
                            Total
                            <br />
                            Volume
                          </h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <div className="circleCard">
                        {epic?<h2>{epic.Highest}</h2>:<h2>0</h2>}
                          <h4>
                            Highest
                            <br />
                            Buy
                          </h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <div className="circleCard">
                        {epic?<h2>{epic.Lowest}</h2>:<h2>0</h2>}
                          <h4>
                            Lowest
                            <br />
                            Buy
                          </h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className="homeBannerImg">
                  <img
                    src={require("../assets/images/avathar2.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </section>
        <section className="homeExplore">
          <div className={classes.container}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                  <div className="homeExploreList">
                    <div className="WoodBgTop"></div>
                    <div className="WoodBgMidd">
                      <nav>
                        <div
                          class="nav nav-tabs nav-fill"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            class="nav-item nav-link create_btn active"
                            id="nav-home-tab"
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            <span>Recently Listed</span>
                          </a>
                          <a
                            class="nav-item nav-link create_btn"
                            id="nav-profile-tab"
                            data-toggle="tab"
                            href="#nav-profile"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            <span>Hot Token List</span>
                          </a>
                        </div>
                      </nav>
                      <div class="tab-content pt-2" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <Scrollbars
                            style={{ width: "100%", height: 560 }}
                            renderTrackVertical={(props) => (
                              <div className="track-vertical" />
                            )}
                          >
                            {tokenListstatus&&<GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div className="itemCard">
                                    <img src={imageloader} alt="Collections" className="img-fluid " />
                                    </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div className="itemCard">
                                    <img src={imageloader} alt="Collections" className="img-fluid " />
                                    </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div className="itemCard">
                                    <img src={imageloader} alt="Collections" className="img-fluid " />
                                    </div>
                            </GridItem>
                            </GridContainer>
                            }
                            <GridContainer>
                              {tokenList.map((item, index) => {
                                return (
                                  <GridItem xs={12} sm={12} md={12}>
                                    <Link to={"/product-info/" + item.tokenId}>
                                    <div className="itemCard">
                                      <div className="itemImage">
                                      {
                                          item.tokenName === "mp4" ?
                                          <>
                                            <img src={require("../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                                            </>
                                            :
                                            item.tokenName === "mp3" ?
                                              <>
                                                <img src={require("../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                                
                                              </>
                                              :
                                              <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                                        }
                                      </div>
                                      <div className="itemDetails">
                                        <h3>
                                          {item.name}<br></br> <small>#{item.tokenId}</small>
                                        </h3>
                                        <p>
                                          From: <span>{item.userId}</span>
                                        </p>
                                        <h4 className="itemPriceCrypto">
                                          <img
                                            src={require("../assets/icon/favicon-32x32.png")}
                                            className="img-fluid logo_img"
                                            alt="Icon"
                                          />{" "}
                                          {item.price}{" "}
                                          {item.tokentype == 1
                                            ? " / BNB"
                                            :item.tokentype == 2?" / ZORO" : " / ORO"}
                                        </h4>
                                        <h6>
                                          <Link
                                            to={"/product-info/" + item.tokenId}
                                          >
                                            <img
                                              src={require("../assets/images/itemArrow.png")}
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </Link>
                                        </h6>
                                      </div>
                                    </div>
                                    </Link>
                                  </GridItem>
                                );
                              })}
                            </GridContainer>
                          </Scrollbars>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <Scrollbars
                            style={{ width: "100%", height: 560 }}
                            renderTrackVertical={(props) => (
                              <div className="track-vertical" />
                            )}
                          >
                            {HotTokenListstatus&&<GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div className="itemCard">
                                    <img src={imageloader} alt="Collections" className="img-fluid " />
                                    </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div className="itemCard">
                                    <img src={imageloader} alt="Collections" className="img-fluid " />
                                    </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div className="itemCard">
                                    <img src={imageloader} alt="Collections" className="img-fluid " />
                                    </div>
                            </GridItem>
                            </GridContainer>
                            }

                            <GridContainer>
                              {HotTokenList && HotTokenList.length>0 && HotTokenList.map((item, index) => {
                                return (
                                  <GridItem xs={12} sm={12} md={12}>
                                    <Link to={"/product-info/" + item.tokenId}>
                                    <div className="itemCard">
                                      <div className="itemImage">
                                      {
                                            item.tokenName === "mp4" ?
                                            <>
                                              <img src={require("../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                                              </>
                                              :
                                              item.tokenName === "mp3" ?
                                                <>
                                                  <img src={require("../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                                 
                                                </>
                                                :
                                                <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                                          }
                                      </div>
                                      <div className="itemDetails">
                                        <h3>
                                          {item.name} <br></br><small>#{item.tokenId}</small>
                                        </h3>
                                        <p>
                                          From: <span>{item.userId}</span>
                                        </p>
                                        <h4 className="itemPriceCrypto">
                                          <img
                                            src={require("../assets/icon/favicon-32x32.png")}
                                            className="img-fluid logo_img"
                                            alt="Icon"
                                          />{" "}
                                          {item.price}{" "}
                                          {item.tokentype == 1
                                            ? " / BNB"
                                            :item.tokentype == 2?" / ZORO": " / ORO"}
                                        </h4>
                                        <h6>
                                          <Link
                                            to={"/product-info/" + item.tokenId}
                                          >
                                            <img
                                              src={require("../assets/images/itemArrow.png")}
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </Link>
                                        </h6>
                                      </div>
                                    </div>
                                    </Link>
                                  </GridItem>
                                );
                              })}
                            </GridContainer>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                    <div className="WoodBgTop"></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <div className="homeBannerImg">
                    <img
                      src={require("../assets/images/avathar3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
