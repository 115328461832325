import React, { useEffect , useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button} from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import ConnectWallet from "./separate/Connect-Wallet";
import $ from 'jquery';
import config from '../config/config';
import { getCurAddr , getuserdetail , UserProfile_Update_Action} from "../actions/users";
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [name,setname] = React.useState("");
  const [url,seturl] = React.useState("");
  const [site,setsite] = React.useState("");
  const [bio,setbio] = React.useState("");
  const [email,setemail] = React.useState("");
  const [TokenFile, setTokenFile] = React.useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = React.useState("");
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const [AddressUserDetails,setAddressUserDetails] = React.useState([]);

  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr == localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          getuserdetails(curAddr);
        } else {
          localStorage.clear();
          window.location.href = "/home";
        }
      }
    } catch (err) {}
  }

  async function getuserdetails(addd){
    var data = {
      address : addd
    }
    var details = await getuserdetail(data);
    if(details && details.data && details.data.data){
      setAddressUserDetails(details.data.data)
      if(details.data.data.name) { setname(details.data.data.name) }else{ setname("") }
      if(details.data.data.url) { seturl(details.data.data.url) }else{ seturl("") }
      if(details.data.data.bio) { setbio(details.data.data.bio) }else{ setbio("") }
      if(details.data.data.personalSite) { setsite(details.data.data.personalSite) }else{ setsite("") }
      if(details.data.data.email) { setemail(details.data.data.email) }else{ setemail("") }
    }
  }

  const namechange = (data) => {
    setname(data.target.value);
  }

  const urlchange = (data) => {
    seturl(data.target.value);
  }

  const biochange = (data) => {
    setbio(data.target.value);
  }

  const sitechange = (data) => {
    setsite(data.target.value);
  }

  const emailchange = (data) => {
    setemail(data.target.value);
  }

  const selectFileChange = (e) => {
    if(e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error("File size exceeds 30 MB", toasterOption);
          $("input[name='image']").val("");
          return false;
        } else {
          setTokenFile(file);
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if(reader.result) {
              setTokenFilePreReader(reader.result);
            }
          }.bind(this);
          setTokenFilePreUrl(e.target.value);
        }
    }
  }

  const FormSubmit = async () => {
   if(localStorage.getItem("ronergodinalklz")){
      var currAddr = await getCurAddr();
    if(currAddr) {
        var formData = new FormData();
        if(TokenFile) {
          formData.append('Image', TokenFile);
        }
        formData.append('addr', currAddr);
        formData.append('name', name);
        formData.append('personalsite', site);
        formData.append('customurl', url);
        formData.append('email', email);
        formData.append('bio', bio);
        var Resp = await UserProfile_Update_Action(formData);
        console.log(Resp,"===========profile")
        if (Resp && Resp.data && Resp.data.success) {
          toast.success("Updated Successfully", toasterOption);
        }else if(Resp && Resp.error && Resp.error.data && Resp.error.data.error && Resp.error.data.message){
          toast.error(Resp.error.data.message, toasterOption);
        }else{
          toast.error("Error occured Try again later", toasterOption);
        }
    }
   }
  }

  return (
    <div className="inner_header">
     <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <div className="single_collectible">
                <div class="card previewCard p-0">
                  <a href="#" class="card__cover mb-0">
                    {
                      AddressUserDetails && AddressUserDetails.image && AddressUserDetails.image!==''
                      ?
                      <img src={config.baseurl+'/profile/'+AddressUserDetails.image} alt="" className="img-fluid" id="imgPreview" />
                      :
                      <img src={TokenFilePreReader!==""? TokenFilePreReader : require("../assets/images/cover/cover3.jpg")} alt="" id="imgPreview" className="img-fluid"/>
                    }
                  </a>
              </div>
              <div className="form-group editProfileUpload">
                    <label className="primary_label text-center mb-3" htmlFor="inputEmail4">We recommend an image of at least 400x400.</label>
                
                  <div className="file_btn primary_btn"><span>Choose File</span>
                    <input className="inp_file" type="file"
                         id="photo"
                         required="true"
                         name="image"
                         onChange={selectFileChange}/>
                  </div>
              </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <h2 className="inner_title">Edit profile</h2>
              <div className="contentBg">
                <h5 className="mb-4 subTitle">You can set preferred display name, create your branded profile URL and manage other personal settings</h5>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Display Name</label>
                        <input type="text" name="" value={name} onChange={namechange} className="form-control" />
                      </div>
                    </GridItem> 
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Custom URL</label>
                        <input type="text" name="" value={url} onChange={urlchange} className="form-control" />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Bio</label>
                        <input type="text" name="" value={bio} onChange={biochange} className="form-control" />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Personal site or portfolio</label>
                        <input type="text" name="" value={site} onChange={sitechange} className="form-control" />
                      </div>
                    </GridItem>
                
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>eMAIL</label>
                        <input type="text" name="" value={email} onChange={emailchange} className="form-control" />
                      </div>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group mb-0 mt-2">
                        <Button type="button" className={classes.navLink + " create_btn px-4"} onClick={() => FormSubmit()}>
                          Update profile
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </form>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
