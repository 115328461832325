import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import styles from "./../../assets/jss/material-kit-react/views/landingPage.js";
import "@metamask/legacy-web3";
import ABI from "../../ABI/ABI.json";
import config from "../../config/config";
import { toast } from "react-toastify";
import Web3 from "web3";
import { updateNotonSale } from "../../actions/users";

toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);

export default function Notforsale(props) {
  const classes = useStyles();

  const [submitButton, setsubmitButton] = useState("");
  const { getNftdataID, saledata } = props;



  async function notForsale() {
    setsubmitButton(1);
    var web3 = new Web3(window.ethereum);
    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
    var setacc = await  web3.eth.getAccounts();
    if (saledata.status === 0 && saledata.offeringID === "") {
      toast.error("Already Product was not onsale  ", toasterOption);
      return false;
    }
    if (saledata.qty === 0) {
      toast.error("Insufficient Quantity", toasterOption);
      return false;
    }

    if (saledata.offeringID === "") {
      toast.error("Already Product on sale  ", toasterOption);
      return false;
    }

    Contract.methods
      .cancelOffering(saledata.offeringID)
      .send({ from: setacc[0] })
      .then(async (result) => {
        var reqData = {
          id: saledata._id,
          qty:saledata.qty,
        };

        await updateNotonSale(reqData);
        await getNftdataID();
        setTimeout(
          () =>
            (window.location.href =
              config.fronturl + "Product-info/" + saledata.tokenId),
          1200
        );

        toast.success(
          "Successfully remove NFT from market place",
          toasterOption
        );
      })
      .catch((error) => {
        toast.error("User cancelled Not for sale", toasterOption);
        setsubmitButton("");
      });
  }
  return (
    <div
      class="modal fade primary_modal"
      id="not_for_sale_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="not_for_sale_modalCenteredLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" id="hide">
          <div class="modal-header text-center">
            <h5 class="modal-title" id="not_for_sale_modalLabel_1">
              Not for Sale
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="update_cover_div_2" id="update_cover_div_2">
            <p>Are you sure ? Remove your NFT from Market Place</p>
              <div className="noteTextMessage">Don't refresh the page when loading</div>
              <div className="text-center my-3">
                <Button
                  className={classes.navLink + " create_btn btn-block mb-4"}
                  onClick={notForsale}
                  disabled={submitButton ? "true" : ""}
                >
                  {submitButton && <i class="fas fa-spinner fa-spin mr-2"></i>}
                  Confirm
                </Button>
                <Button
                  className={classes.navLink + " create_btn_black btn-block"}
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
