import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import styles from "./../../assets/jss/material-kit-react/views/landingPage.js";
import "@metamask/legacy-web3";
import ABI from "../../ABI/ABI.json";
import config from "../../config/config";
import { toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Web3 from "web3";
import { updatePutonSale } from "../../actions/users";
import { checkwhiteaddress } from '../../actions/users';


toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);

export default function PutonSale(props) {
  const [submitButton, setsubmitButton] = React.useState("");
  const { getNftdataID, saledata } = props;

  const [price, setprice] = useState();
  const [qty, setquantity] = useState();
  const [token, setToken] = useState("BNB");
  const [allowzoro,setallowzoro] = useState(false);

  const cancelfunction = () => {
    setprice("");
    setquantity("");
  }

useEffect(()=>{
init()
},[])

const init=(async()=>{
  let web3 = new Web3(window.ethereum);
  let setacc = await web3.eth.getAccounts();
      let reqdata={
        "address" : setacc[0]
      }
  let {data} = await checkwhiteaddress(reqdata);
      if(data && data.data && data.data.length>0){
        setallowzoro(data.data[0].isAdmin)
      }else{
        setallowzoro(false)
      }
})

  const changeqty = (e) => {
    console.log('qty', e.target.value);
    setquantity(e.target.value);
  }
  const changeQuan = (quan) => {
    setprice(quan.target.value);
  };

  const changeToken = (tok) => {
    setToken(tok.target.value);
  };

  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
     
    } else {
      setquantity("")
    }
  }

  async function saleToken() {
    console.log(price, 'validprice')
    console.log(qty, 'validqty')

    if (qty <= 0 || qty === "" || qty === undefined) {
      toast.error("Please Enter Valid Quantity", toasterOption);
      return false;
    }
    if (saledata.qty < qty) {
      toast.error("Please enter below holeded quantity", toasterOption)
      return false;
    }

    if (price <= 0 || price === "" || price === undefined) {
      toast.error("Please Enter Valid Price", toasterOption);
      return false;
    }



    setsubmitButton(1);
    var web3 = new Web3(window.ethereum);
    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
    var setacc = await web3.eth.getAccounts();
    console.log("setacc===", setacc[0])

    if (saledata.qty === 0) {
      toast.error("Insufficient Quantity", toasterOption);
      setsubmitButton("");
      return false;
    }

    if (saledata.status !== 0) {
      toast.error("Already Product on sale  ", toasterOption);
      setsubmitButton("");
      return false;
    }

    if (saledata.offeringID !== "0") {
      toast.error("Already Product on sale  ", toasterOption);
      setsubmitButton("");
      return false;
    }
    var priceNew = price;
    Contract.methods
      .placeOffering(
        config.NFTCONTRACTMINT,
        saledata.tokenId,
        qty,
        web3.utils.toWei(priceNew.toString()),
        token
      )
      .send({ from: setacc[0] })
      .then(async (result) => {
        console.log(result, "resultresultresultresultresult");
        var reqData = {
          price: price,
          quantity: qty,
          tokenType: token,
          id: saledata._id,
          offeringId: result.events.OfferingPlaced.returnValues.offeringId,
        };

        console.log(reqData, "reqDatareqDatareqData");
        var resultSave = await updatePutonSale(reqData);
        console.log(resultSave);

        toast.success("Successfully Make NFT for sale", toasterOption);

        await getNftdataID();

        setTimeout(
          () =>
          (window.location.href =
            config.fronturl + "Product-info/" + saledata.tokenId),
          1200
        );
        console.log("Success", result.events.OfferingPlaced.offeringId);
      })
      .catch((error) => {
        toast.error("User cancelled Put on sale", toasterOption);
        setsubmitButton("");
        console.log("Error", error);
      });
  }

  const classes = useStyles();
  return (
    <div>
      <div
        class="modal fade primary_modal"
        id="put_sale_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="put_sale_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content" id="hide">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="put_sale_modalLabel_1">
                Put on Sale
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => cancelfunction()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <form>
                  <div className="form-group formSkew">
                    <div className="input-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Quantity"
                        onKeyUp={onKeyUp}
                        onChange={(e) => {
                          changeqty(e);
                        }}
                        value={qty}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group formSkew">
                    <div className="input-group">
                      <input
                        type="text"
                        name=""
                        value={price}
                        className="form-control"
                        onChange={(e) => {
                          changeQuan(e);
                        }}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">
                          <Select
                            displayEmpty
                            className={classes.selectEmpty + " selectSkew"}
                            inputProps={{ "aria-label": "Without label" }}
                            value={token}
                            onChange={(e) => {
                              changeToken(e);
                            }}
                          >
                            <MenuItem value={"BNB"}>BNB</MenuItem>
                            <MenuItem value={"ORO"}>OR0</MenuItem>
                            {allowzoro&&<MenuItem value={"ZORO"}>ZOR0</MenuItem>}
                          </Select>
                        </span>
                      </div>
                    </div>
                  </div>
                  <p class="form_note text-grey mb-0">
                    Royalty amount {saledata && saledata.royal} % will
                    send to Creator
                    <span className="text-white">
                    </span>{" "}
                  </p>
                  <div className="noteTextMessage">Don't refresh the page when loading</div>
                  <div className="text-center my-3">
                    <Button
                      className={classes.navLink + " create_btn btn-block mb-4"}
                      onClick={saleToken}
                      disabled={submitButton ? "true" : ""}
                    >
                      {submitButton && (
                        <i class="fas fa-spinner fa-spin mr-2"></i>
                      )}
                      Put on sale
                    </Button>
                    <Button
                      className={
                        classes.navLink + " create_btn_black btn-block"
                      }
                      data-dismiss="modal"
                      onClick={() => cancelfunction()}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
