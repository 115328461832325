import React, { useEffect, useState  } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import moment from "moment"
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DataTable from 'react-data-table-component';
import ConnectWallet from "./separate/Connect-Wallet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCurAddr , createtickets , getticketlist , ticketclose , submitreply} from "../actions/users";
import { toast } from "react-toastify";
import config from "../config/config"
toast.configure();
let toasterOption = config.toasterOption;

const dashboardRoutes = [];

// Trade History Table
const table_data = [];
const table_columns = [
  {                                                                     
    name: 'Date',
    selector: 'created_date',
  },
  {
    name: 'Ticket ID',
    selector: 'ticketno',
  },
  {
    name: 'Issue',
    selector: 'subject', 
    width: "325px"
  },
  {
    name: 'Status',
    selector: 'closedstatus',
    
  }
];


const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function SupportTicket(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [email,setemail] = useState("");
  const [issue,setissue] = useState("");
  const [describtion,setdescribtion] = useState("")
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const [tokenlist,settokenlist] = useState([])
  const [reply,setreply] = useState("")

  const emailchange = (e) =>{
     setemail(e.target.value)
  }

  const selectchange = (e) =>{
    setissue(e.target.value)
  }

  const describtionchange = (e) =>{
    setdescribtion(e.target.value)
  }

  async function createticket(){
    if(issue===""){
      toast.warning("Please choose issue", toasterOption);
      return false;
     }

     if(describtion===""){
      toast.warning("Please Describe your issue", toasterOption);
      return false;
     }
    // if(email!=""){
      if(issue!=""){
        var datas = {
          "email" : email,
          "issue" : issue,
          "describtion" : describtion,
          "address" : localStorage.getItem("address")
        }
        var update = await createtickets(datas);
        if(update && update.data && update.data.message){
          toast.success(update.data.message, toasterOption);
          getlist(localStorage.getItem("address"))
        }
      }
    // }
    // else{
    //   toast.warning("Please enter email", toasterOption);
    // }
    setdescribtion("")
  }

  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr == localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          await getlist(curAddr)
        } else {
          localStorage.clear();
          window.location.href = "/";
        }
      }else{
        localStorage.clear();
          window.location.href = "/";
      }
    } catch (err) {}
  }

  async function getlist(addd){
    var datas = {
      address : addd
    }
    var lists = await getticketlist(datas); 
    if(lists && lists.data && lists.data.data){
      settokenlist(lists.data.data)
    } 
  }

  async function replysend(iid){
    if(reply && reply!="" && reply!=null && reply!=undefined) {
     var datas = {
      "id" : iid,
      userid:localStorage.getItem("address"),
      message_requery:reply,
    }
    var lists = await submitreply(datas); 
    if(lists && lists.data && lists.data.message){
      toast.success("reply Submited Successfully", toasterOption);
      getlist(localStorage.getItem("address"))
    } 
   }else{
      toast.error("Please Enter Your Message", toasterOption);
   }
  }

  async function closeticket(iiiid){
    var datas = {
      "id" : iiiid
    }
    var lists = await ticketclose(datas); 
    if(lists && lists.data && lists.data.message){
      toast.success("Ticket Closed Successfully", toasterOption);
      getlist(localStorage.getItem("address"))
    } 
  }

  const replaychange = (e) => {
    setreply(e.target.value)
  }

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <div className="contentBg supportForm">
              <div className="sub_Box_title">
                      <h2>Create Ticket</h2>
                    </div>
                <form> 
                  <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group formSkew">
                        <label>Email Address</label>
                        <input type="text" name="" onChange={emailchange} className="form-control" />
                      </div>
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group mb-0">
                        <label className="d-block w-100">Select Your Issue</label>
                        <Select
                          className={classes.selectEmpty + " selectSkew w-100"}
                          inputProps={{ 'aria-label': 'Without label' }} onChange={selectchange}
                        >
                          <MenuItem value="Minting">Minting</MenuItem>
                          <MenuItem value="Transfer">Transfer</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </div>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group formSkew textAreaSkew mt-5">
                        <label className="mb-0">Describe your Issue</label>
                        <textarea className="form-control" value={describtion} onChange={describtionchange}></textarea>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group mb-0 mt-2">
                        <Button type="button" onClick={()=>createticket()} className={classes.navLink + " create_btn px-4"}>
                          Create Ticket
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </form>
              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <h2 className="inner_title">Support ticket</h2>
              <div className="contentBg">
              <div className="inner_pages_wrapper_panel">
                <div className="sub_Box_title">
                  <h2 className="mb-0">Ticket History</h2>
                </div>
                <DataTable className="history_table" columns={table_columns} data={tokenlist} pagination={true} paginationPerPage="5" paginationRowsPerPageOptions={[5, 10, 15, 20]} />
                </div>
                <div className="inner_pages_wrapper_panel mb-0">
                <div className="inner_pages_wrapper_title">
                  <h2>Ticket Details</h2>
                </div>
                <div className="inner_pages_wrapper_content">
                  <GridContainer>
                 {tokenlist && tokenlist.length>0 && tokenlist.map((item)=>{
                    return (
                      <GridItem sm={12} md={12}>
                      <div className="dash_wrapper">
                        <div className="support_details_header">
                          <h2>Sub: {item.subject}</h2>
                          <h2>Des: {item.description}</h2>
                        </div>
                        <p className="tickets_info">Create on: <span>{moment(item.created_date).format('MMMM Do YYYY, h:mm:ss a')}</span>  |  Ticket ID: <span>#{item.ticketno}</span> | Status: <span className="yellow_txt">{item.closedstatus}</span></p>
                        <div className="dash_padding_wrapper">
                          <div className="support_ticket_panel">                            
                            {(item.reply > 0
                              ? item.reply
                              : item.reply
                            ).map((replay) => (
                            <div className="support_ticket_row">
                             {replay.replytype == "user" ? (
                              <img src={require("../assets/images/user.png")} className="img-fluid user_img" alt="Icon" />
                             ) : ( 
                              <img src={require("../assets/icon/favicon-32x32.png")} className="img-fluid user_img" alt="Icon" />
                             )}
                             <div className="ticket_details">
                             <p className="ticket_time">{moment(replay.replydate).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                <p>Hello {replay.replytype == "user" ? "Admin":"User"},</p>
                                <p>{replay.message_query}</p>
                                <p>Thanks. <br/></p>
                             </div>
                            </div>
                            ))}
                           {item.closedstatus=="Closed"? 
                            "" 
                           :
                            <form className="auth_form px-5">
                            <div className="flexLabel">
                            <label htmlFor="ticketdesc" className="auth_label">
                              <span>Reply</span>
                              </label>
                              {/*<div className="editProfileUpload">
                                    <div className="file_btn primary_btn"><span><i class="fas fa-paperclip"></i></span>
                                      <input className="inp_file" type="file" name="file"/>
                                    </div>
                                </div>*/}
                                </div>
                            <div className="form-group formSkew textAreaSkew supportReply">
                              <textarea className="form-control" onChange={replaychange}></textarea>
                            </div>
                              <div className="chatBottom">
                                  <Button className="create_btn px-4" onClick={() => {replysend(item._id)}}>Submit</Button>
                                  <a href="#" onClick={() => {closeticket(item._id);}}>Satisfied, Close this ticket</a>
                              </div>
                            </form>}
                          </div>
                        </div>
                      </div>
                    </GridItem>  
                    )
                  })}
                  </GridContainer>
                </div>
              </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>

          
        
      </div>
      <Footer/>

    </div>
  );
}
