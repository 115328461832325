// import React
import { createContext } from "react";

const Productcontext = createContext({
  dataNft: "",
  singdataNft: "",
  ownerDetails: "",
});

export default Productcontext;
