import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Details from "./nftcomponents/details";
import { getNftdata, getCurAddr, getOwnerDetails } from "../actions/users";
import ConnectWallet from "./separate/Connect-Wallet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProductContext from "./ProductContext";
toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ProductInfo(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [dataNft, setdataNft] = useState("");
  const [singdataNft, setsingdataNft] = useState("");
  const [ownerDetails, setownerDetails] = useState("");
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const address =localStorage.getItem("address");
  const { id } = useParams();

  const getNftdataID = async () => {
    var dataNft = await getNftdata(id);
    var reqData = {
      tokeId: id,
      ownerId: localStorage.getItem("address"),
    };
    var dataNftOwn = await getOwnerDetails(reqData);
    console.log(dataNftOwn,"Gooddata")
    setownerDetails(dataNftOwn.result.data);
    setdataNft(dataNft.result.data);
    setsingdataNft(dataNft.result.data[0]);
  };

  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr === localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          getNftdataID();
        } else {
          localStorage.clear();
          window.location.reaload();
        }
      }
    } catch (err) {}
  }

  useEffect(() => {
    getNftdataID();
  }, []);

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <img
            src={require("../assets/images/logo.png")}
            alt="logo"
            className="img-fluid"
          />
        }
        rightLinks={<HeaderLinksAfterLogin />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <ProductContext.Provider value={{ dataNft, singdataNft, ownerDetails }}>
        {address&&<Details
          setdataNft={setdataNft}
          setsingdataNft={setsingdataNft}
          getNftdataID={getNftdataID}
        />}
      </ProductContext.Provider>
      <Footer />
    </div>
  );
}
