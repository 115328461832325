import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import config from "../config/config";
import ABI from "../ABI/ABI.json";
import {
  getCurAddr,
  getmyeventlist,
  getsaleTokenstop,
  getownTokenstop,
  ipfsImageHashGet,
  ipfsmetadata,
  saveNft,
} from "../actions/users";
import Web3 from "web3";
import validation from "./validatation/validation";
import isEmpty from "./validatation/isEmpty";
import ConnectWallet from "./separate/Connect-Wallet";

const dashboardRoutes = [];
var setacc = [];
const useStyles = makeStyles(styles);

toast.configure();
let toasterOption = config.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getaddress();
  }, []);
  return null;
}

async function getaddress() {
  var web3 = new Web3(window.ethereum);
  setacc = await web3.eth.getAccounts();
  console.log("setacc===", setacc[0]);
}

const initialFormValue = {
  name: "",
  descp: "",
  royal: 5,
  properties: "",
  cat: "",
  qty: "",
  rarity: "",
  affnity: "",
  attack: "",
  defence: "",
  health: "",
  TokenName: "",
  tokenId: "",
  jsonDataUrl: "",
  imageDataUrl: "",
  userId: "",
  TokenFileType: "",
};

export default function CreateSingle(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [TokenFile, setTokenFile] = React.useState("");
  const [submitButton, setsubmitButton] = React.useState("");
  const [TokenName, setTokenName] = React.useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = React.useState("");
  const [validateError, setValidateError] = useState({});
  const [formValue, setFormValue] = useState(initialFormValue);
  const [ipfshash, setIpfsHash] = useState("");
  const [ipfshashurl, setipfshashurl] = useState("");
  const [ipfsmetatag, set_ipfsmetatag] = useState("");
  const [userid, setuserid] = useState("");

  const {
    name,
    descp,
    royal,
    properties,
    cat,
    qty,
    rarity,
    affnity,
    attack,
    defence,
    health,
    tokenId,
    jsonDataUrl,
    imageDataUrl,
    userId,
    TokenFileType,
  } = formValue;

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  const selectFileChange = (e) => {
    var validExtensions = ["png", "gif", "mp4", "mp3", "webp"];
    if (e.target && e.target.files) {
      var reader = new FileReader();
      var file = e.target.files[0];
      console.log("onchange_file : ", file);
      var fileName = file.name;
      var fileType = file.type;
      // setTokenName(fileType);
      var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      setTokenName(fileNameExt);
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        toast.error(
          "Only these file types are accepted : " + validExtensions.join(", "),
          toasterOption
        );
        $("input[name='image']").val("");
        return false;
      } else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error("File size exceeds 30 MB", toasterOption);
          $("input[name='image']").val("");
          return false;
        } else {
          setTokenFile(file);
          let id = "TokenFileType";
          let loadFile = fileType;
          let formData = { ...formValue, ...{ [id]: loadFile } };
          setFormValue(formData);
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if (reader.result) {
              console.log("reader.result", reader.result);
              setTokenFilePreReader(reader.result);
            }
          }.bind(this);
          setTokenFilePreUrl(e.target.value);
        }
      }
    }
  };

  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr == localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          setacc[0] = curAddr;
        } else {
          localStorage.clear();
          window.location.href = "/home";
        }
      }
    } catch (err) {}
  }

  const handleDropdown = (e) =>{
    const {name,value} = e.target
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  }

  async function createNft() {
    console.log("formvalue", formValue);
    let validationError = validation(formValue);
    // if(!TokenFilePreReader){
    //   validateError.file="please select your file";
    // }
    setValidateError(validationError);
    if (!isEmpty(validationError)) {
      console.log(validateError);
      return false;
    }
    setsubmitButton(1);

    var unix = Math.round(+new Date() / 1000);
    let ran = Math.floor(Math.random() * 100 + 1);
    let tokenId = unix + ran;

    var web3 = new Web3(window.ethereum);
    // setuserid();
    let reqData = {
      name,
      descp,
      royal,
      properties,
      cat,
      qty,
      rarity,
      affnity,
      attack,
      defence,
      health,
      TokenFile,
      TokenName,
      tokenId,
      jsonDataUrl,
      imageDataUrl,
      userId: setacc[0],
    };
    console.log("reqData", reqData);
    var ipfsimghashget = await ipfsImageHashGet(reqData);

    if (ipfsimghashget.data !== undefined) {
      if (ipfsimghashget.data.ipfsval != "") {
        setIpfsHash(ipfsimghashget.data.ipfsval);
        reqData.imageDataUrl =
          config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval;
        setipfshashurl(`${config.IPFS_IMG}/${ipfsimghashget.data.ipfsval}`);
      }

      var ipfsmetatagVal = await ipfsmetadata(reqData);

      if (ipfsmetatagVal.data !== undefined) {
        if (ipfsmetatagVal.data.ipfsval != "") {
          var ipfsurl = ipfsmetatagVal.data.ipfsval;
          reqData.jsonDataUrl =
            config.IPFS_IMG + "/" + ipfsmetatagVal.data.ipfsval;
          set_ipfsmetatag(`${config.IPFS_IMG}/${ipfsurl}`);
        }
      }
    }

    console.log(reqData, "reqDatareqDatareqDatareqData");
    // alert("saran");

    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
    var royaltyAmount = royal * 1000000000000000000;
    Contract.methods
      .mint(
        setacc[0],
        tokenId,
        qty,
        config.NFTCONTRACTMINT,
        royaltyAmount.toString()
      )
      .send({ from: setacc[0] })
      .then(async (result) => {
        var ipfsimghashget = await saveNft(reqData);
        console.log("nftdata", ipfsimghashget);
        toast.success("Successfully created", toasterOption);
        setTimeout(
          () =>
            (window.location.href =
              config.fronturl + "Product-info/" + tokenId),
          1200
        );
        setsubmitButton("");
      })
      .catch((error) => {
        toast.error("User Rejected", toasterOption);
        setsubmitButton("");
      });
  }

  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      var ValidateError = {};
      // Set_ValidateError(ValidateError);
    } else {
      setFormValue({ ...formValue, ...{ ["qty"]: "" } });
    }
    console.log(formValue.qty, "qtycheck");
  };
  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <img
            src={require("../assets/images/logo.png")}
            alt="logo"
            className="img-fluid"
          />
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <div className="single_collectible">
                <div class="card previewCard p-0">
                  <a href="#" class="card__cover mb-0">
                    {TokenFilePreUrl.split(".").pop() == "mp4" ? (
                      <video
                        id="imgPreview"
                        src={
                          TokenFilePreReader != ""
                            ? TokenFilePreReader
                            : require("../assets/images/cover/cover3.jpg")
                        }
                        type="video/mp4"
                        alt="Collection"
                        className="img-fluid"
                        autoPlay
                        playsInLine
                        loop
                        muted
                      ></video>
                    ) : TokenFilePreUrl.split(".").pop() == "mp3" ? (
                      <>
                        <img
                          src={require("../assets/images/audio-large.jpg")}
                          alt="Collections"
                          className="img-fluid"
                        />

                        <audio
                          id="audioPreview"
                          src={
                            TokenFilePreReader != ""
                              ? TokenFilePreReader
                              : require("../assets/images/woodx.jpg")
                          }
                          type="audio/mp3"
                          controls
                          className="audio"
                        ></audio>
                      </>
                    ) : (
                      <img
                        src={
                          TokenFilePreReader != ""
                            ? TokenFilePreReader
                            : require("../assets/images/cover/cover3.jpg")
                        }
                        id="imgPreview"
                        alt="Collections"
                        className="img-fluid"
                      />
                    )}
                  </a>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <h2 className="inner_title">Create Token</h2>
              <div className="contentBg">
                <form>
                  <div className="form-group">
                    <div className="d-flex justify-content-start align-items-start">
                      <div className="mr-5">
                        <label className="primary_label" htmlFor="inputEmail4">
                          Upload file
                        </label>
                        <p className="form_note">
                          PNG, GIF, WEBP, MP4 or MP3. Max 30mb.
                        </p>
                      </div>
                      <div className="file_btn primary_btn">
                        <span>Upload</span>
                        <input
                          className="inp_file"
                          type="file"
                          accept="audio/*,video/*,image/*"
                          name="image"
                          onChange={selectFileChange}
                        />
                      </div>
                    </div>
                    {validateError.file && (
                      <p className="validation">{validateError.file}</p>
                    )}
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                      {validateError.name && (
                        <p className="validation">{validateError.name}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Description (Optional)</label>
                        <input
                          type="text"
                          name="descp"
                          id="descp"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Royalties</label>
                        <input
                          type="text"
                          name="royal"
                          id="royal"
                          value={royal}
                          readOnly
                          className="form-control"
                          // onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                      {validateError.royal && (
                        <p className="validation">{validateError.royal}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Properties (Optional)</label>
                        <input
                          type="text"
                          name="properties"
                          id="properties"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group mb-0">
                        <label className="d-block w-100">Category</label>
                        <Select
                          displayEmpty
                          className={classes.selectEmpty + " selectSkew w-100"}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </div>
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Quantity</label>
                        <input
                          type="text"
                          name="qty"
                          value={formValue.qty}
                          id="qty"
                          className="form-control"
                          onKeyUp={onKeyUp}
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                      {validateError.qty && (
                        <p className="validation">{validateError.qty}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group">
                        <label className="mr-2">Rarity</label>
                        <Select
                          className={classes.selectEmpty + " selectSkew w-100"}
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={handleDropdown}
                          value={formValue.rarity}
                          id="rarity"
                          name="rarity"
                        >
                          <MenuItem value="common" id="common" >Common</MenuItem>
                          <MenuItem value="rare" id="rare">Rare</MenuItem>
                          <MenuItem value="epic" id="epic">Epic</MenuItem>
                          <MenuItem value="legendary" id="legendary">Legendary</MenuItem>
                        </Select>
                      </div>
                      {/* <div className="form-group formSkew">
                        <label>Rarity</label>
                        <input
                          type="text"
                          name="rarity"
                          id="rarity"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div> */}
                      {validateError.rarity && (
                        <p className="validation mt-4">{validateError.rarity}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                       <div className="form-group">
                        <label className="mr-2">Affinity</label>
                        <Select
                          className={classes.selectEmpty + " selectSkew w-100"}
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={handleDropdown}
                          value={formValue.affnity}
                          id="affnity"
                          name="affnity"
                        > 
                          <MenuItem value="earth" id="earth">Earth</MenuItem>
                          <MenuItem value="fire" id="fire">Fire</MenuItem>
                          <MenuItem value="ice" id="ice">Ice</MenuItem>
                          <MenuItem value="magic"id="magic">Magic</MenuItem>
                        </Select>
                      </div>
                      {validateError.affnity && (
                        <p className="validation mt-4">{validateError.affnity}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Attack</label>
                        <input
                          type="text"
                          name="attack"
                          id="attack"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                      {validateError.attack && (
                        <p className="validation">{validateError.attack}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Defence</label>
                        <input
                          type="text"
                          name="defence"
                          id="defence"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                      {validateError.defence && (
                        <p className="validation">{validateError.defence}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="form-group formSkew">
                        <label>Health</label>
                        <input
                          type="text"
                          name="health"
                          id="health"
                          className="form-control"
                          onChange={onChange}
                          autoComplete="off"
                        />
                      </div>
                      {validateError.health && (
                        <p className="validation">{validateError.health}</p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group mb-0 mt-2">
                        <Button
                          className={classes.navLink + " create_btn px-4"}
                          onClick={createNft}
                          disabled={submitButton ? "true" : ""}
                        >
                          {submitButton && (
                            <i class="fas fa-spinner fa-spin mr-2"></i>
                          )}
                          Create Item
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </form>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />

      {/* Choose Collection Modal */}
      <div
        class="modal fade primary_modal"
        id="choose_collection_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="choose_collection_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="choose_collection_modalLabel">
                Collection
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex align-items-center">
                <div class="hex center">
                  <div class="hex__shape">
                    <div class="hex__shape">
                      <div class="hex__shape">
                        <img
                          src={require("../assets/images/img_01.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_16 font_600 line_20 mb-1">
                    We recommend an image of at least 400x400. Gifs work too.
                  </p>
                  <div className="file_btn primary_btn d-inline-block">
                    Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Display Name{" "}
                      <span className="text-muted">(30 available)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="name"
                      placeholder="Enter token name"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">
                      Symbol <span className="text-muted">(required)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="desccription"
                      placeholder="Enter token symbol"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Description <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="name"
                      placeholder="Spread some words about token collection"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">
                      Short url
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="desccription"
                      defaultValue="Ultrasafe.io/"
                    />
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Create Item Modal */}
      <div
        class="modal fade primary_modal"
        id="create_item_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="create_item_modalLabel">
                Follow Steps
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="media approve_media">
                  <i
                    className="fas fa-check mr-3 pro_complete"
                    aria-hidden="true"
                  ></i>
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">
                      Approve performing transactions with your wallet
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">
                      Upload files & Mint token
                    </p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block">Start</Button>
                </div>

                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign sell order</p>
                    <p className="mt-0 approve_desc">
                      Sign sell order using tour wallet
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled>
                    Start
                  </Button>
                </div>

                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign lock order</p>
                    <p className="mt-0 approve_desc">
                      Sign lock order using tour wallet
                    </p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled>
                    Start
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
