import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { getfaqlists } from "../actions/users";

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #4cc0e9;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #4cc0e9;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function HowItWorks(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel0');
  const [faqlist, setfaqlist] = React.useState([]);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function getfaqlist() {
    var faqs = await getfaqlists();
    if(faqs && faqs.result && faqs.result.data){
      setfaqlist(faqs.result.data)
    }
  }

  useEffect(() => {
    getfaqlist()
  }, []);

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">Frequently Asked Questions</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="m-auto">
              <div className="faq_panel">
              {faqlist.map((item, index) => {
                  return (
                   <Accordion expanded={expanded === 'panel'+index} onChange={handleChangeFaq('panel'+index)} className="mt-5">
                      <AccordionSummary expandIcon={<Icon />} aria-controls={"panel"+index+"bh-content"} id={"panel"+index+"bh-header"}>
                        <div className="accordian_head"><h2>{item.question}</h2></div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordian_para">
                          <p>{item.answer}</p>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
