/*eslint-disable*/
import React,  { useState, useEffect } from "react";
import {
  Notifications,
  AccountBalanceWallet,
  FileCopy,
} from "@material-ui/icons";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";
import Heardersearch from "../../views/separate/HeaderSearch"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button } from "@material-ui/core";
import { checkwhiteaddress,getCurAddr } from '../../actions/users';
import copy from "copy-to-clipboard"; 
import Modal from "react-modal";
import config from "../../config/config";
import Web3 from "web3";
import "@metamask/legacy-web3";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);
var mynetwork = config.NETWORKVERSION;

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [createshow,setcreateshow] = useState(false)
  const [Metaaddress,setMetaaddress] = useState(false)


  useEffect(() => {
      if (
        localStorage.getItem("ronergodinalklz") &&
        localStorage.getItem("ronergodinalklz") != null &&
        localStorage.getItem("ronergodinalklz") != undefined &&
        localStorage.getItem("ronergodinalklz") != ""
      ){
    getUserData();
    }
  }, [])

  async function getUserData(){
    setTimeout(async function(){
                  const web3 = new Web3(window.web3.currentProvider);
                  console.log(mynetwork,"mynetworkmynetworkmynetwork",window.web3.currentProvider.networkVersion)
                  var currnetwork = await web3.eth.net.getId();
                  console.log(currnetwork,"currnetwork");

                  if (currnetwork == mynetwork) {
               // console.log(window.web3.currentProvider.isMetaMask ,"window.web3.currentProvider.isMetaMask window.web3.currentProvider.isMetaMask" )
                    if (window.web3.currentProvider.isMetaMask === true) {
                   
                      if (
                        window.web3 &&
                        window.web3.eth &&
                        window.web3.eth.defaultAccount
                      ) {
                          var setacc = await  web3.eth.getAccounts()
                          // localStorage.removeItem("ronergodinalklz");
                          // localStorage.removeItem("address");
                          // setMetaaddress(setacc[0]);
                          localStorage.setItem("ronergodinalklz", "yes");
                          localStorage.setItem("address", setacc[0]);
                          console.log(setacc,localStorage.getItem('address'));
                          var data={
                            "address" :setacc[0]
                          }
                          console.log("checkwhitelist",data);
                          var test = await checkwhiteaddress(data);
                          if(test && test.data && test.data.data && test.data.data.length > 0){
                            setcreateshow(true);
                          }else{
                            setcreateshow(false);
                          }
                        
                }
              }
            }
          },500)
  }
  const copyToClipboard = () => {
    copy(localStorage.getItem("address"));
    toast.success("copy address");
 }
  async function disconnectMetamask(event) {
    localStorage.removeItem("balance");
    localStorage.removeItem("ronergodinalklz");
    localStorage.removeItem("address");
    // localStorage.setItem("disconnect",true);
    window.location.href="/"
  }

  return (
    <List className={classes.list + " main_navbar"}>
      {/*<ListItem className={classes.listItem}>
        <div className="search_inp_group formSkew">
          <input
            type="text"
            className="search_inp form-control"
            placeholder="Search items / creators"
          />
          <div className="search_inp_group_append">
            <i className="fas fa-search"></i>
          </div>
        </div>
      </ListItem>*/}
      <Heardersearch />
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/explore">
          Explore
        </NavLink>
      </ListItem>
      {localStorage.getItem("ronergodinalklz") && (
        <ListItem className={classes.listItem}>
          <NavLink className={classes.navLink} to="/my-items">
            My items
          </NavLink>
        </ListItem>
      )}

     {createshow && 
      <ListItem className={classes.listItem + " ml-3"}>
        <Button className={classes.navLink + " create_btn"}>
          <Link to="/create-single">Create</Link>
        </Button>
      </ListItem>
     }

      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/faq">
          FAQ
        </NavLink>
      </ListItem>

      {!localStorage.getItem("ronergodinalklz") && (
        <ListItem className={classes.listItem + " ml-3"}>
          <Button className={classes.navLink + " create_btn"}>
            <Link to="/connect">Connect Wallet</Link>
          </Button>
        </ListItem>
      )}

      {localStorage.getItem("ronergodinalklz") && (
        <ListItem
          className={
            classes.listItem +
            " menu_dropdown dropdown_header_ul noti_ul wallet_drop marginSideMenuSpace"
          }
        >
          <CustomDropdown
            noLiPadding
            buttonText={
              <div>
                <Button className={classes.navLink + " create_btn"}>
                  <AccountBalanceWallet className="menu_icons" />
                </Button>
              </div>
            }
            dropdownList={[
              <div>
                <div className="noti_parent">
                  <div className="d-flex align-items-center">
                    <p className="wallet_address">
                    {localStorage.getItem("address")}
                    </p>{" "}<FileCopy onClick={copyToClipboard}/>
                    {/* <FileCopy className="ml-2 wllet_copy_icon text-white" /> */}
                  </div>
                  <div className="wallet_balance">
                    <h4>Balance</h4>
                    <p> {localStorage.getItem("balance")} BNB</p>
                  </div>
                </div>
                <hr />
                <div className="noti_parent">
                  <NavLink to="/my-items" className={classes.dropdownLink}>
                    My Items
                  </NavLink>
                  <NavLink to="/edit-profile" className={classes.dropdownLink}>
                    Edit Profile
                  </NavLink>
                  <Button
                    type="submit"
                    className={classes.dropdownLink + " p-0 ml-0"}
                    onClick={(event) => disconnectMetamask(event)}
                  >
                    Disconnect
                  </Button>
                </div>
              </div>,
            ]}
          />
        </ListItem>
      )}
    </List>
  );
}
