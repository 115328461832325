import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import styles from "./../../assets/jss/material-kit-react/views/landingPage.js";
import "@metamask/legacy-web3";
import ABI from "../../ABI/ABI.json";
import config from "../../config/config";
import { toast } from "react-toastify";
import ProductContext from "../ProductContext";
import Web3 from "web3";
import { updateTransfer } from "../../actions/users";

toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);


const Transfer = forwardRef((props, ref) => {
  const classes = useStyles();



  useImperativeHandle(ref, () => ({
    openModal() {
    },
  }));

  const productContext = useContext(ProductContext);
  var ownerDetails = productContext.ownerDetails;
  const [submitButton, setsubmitButton] = React.useState("");
  const { getNftdataID } = props;

  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState();

  const cancelfunction = () => {
    setAmount("");
    setAddress("");
  };

  const changeAmount = (price) => {
    setAmount(price.target.value);
  };

  const changeAddress = (address) => {
    setAddress(address.target.value);
  };

  async function safetransfer() {
    var web3 = new Web3(window.ethereum);
    var setacc = await web3.eth.getAccounts();

    if (address === "") {
      toast.warning("Address is required", toasterOption);
      return false;
    }

    let check_address = web3.utils.isAddress(address);
    if (check_address === false) {
      toast.warning("Invalid Address", toasterOption);
      return false;
    }
    if (setacc[0] === address) {
      toast.warning("Please enter another wallet address", toasterOption);
      return false;
    }

    if (amount <= 0 || undefined || amount === "") {
      toast.warning("Please Enter Valid Quantity", toasterOption);
      return false;
    }
    if (amount > ownerDetails.qty || amount === 0) {
      toast.warning("Insufficient Quantity", toasterOption);
      return false;
    }
    setsubmitButton(1);

    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);

    Contract.methods
      .transfer(
        setacc[0],
        address,
        ownerDetails.tokenId,
        amount,
        config.NFTCONTRACTMINT,
        ownerDetails.offeringID !== "0" &&
          ownerDetails.offeringID !== "" &&
          ownerDetails.offeringID !== 0
          ? ownerDetails.offeringID
          : "0x",
        ownerDetails.offeringID !== "0" &&
          ownerDetails.offeringID !== "" &&
          ownerDetails.offeringID !== 0
          ? 1
          : 0
      )
      .send({ from: setacc[0] })
      .then(async (result) => {
        var reqData = {
          qty: amount,
          buyer: address,
          id: ownerDetails._id,
          "result":result
        };
        await updateTransfer(reqData);

        toast.success("Successfully transfered", toasterOption);

        await getNftdataID();

        setTimeout(
          () =>
            (window.location.href =
              config.fronturl + "Product-info/" + ownerDetails.tokenId),
          1200
        );
      })
      .catch((error) => {
        toast.error("User cancelled Transfer", toasterOption);
        setsubmitButton("");
      });
  }

  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
    } else {
      setAmount("");
    }
  };
  return (
    <div
      class="modal fade primary_modal"
      id="transfer_sale_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="transfer_sale_modalCenteredLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" id="hide">
          <div class="modal-header text-center">
            <h5 class="modal-title" id="transfer_sale_modalLabel_1">
              Transfer Token
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => cancelfunction()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="update_cover_div_2" id="update_cover_div_2">
              <form>
                <div className="form-group formSkew">
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      placeholder="To Addresss"
                      onChange={changeAddress}
                      value={address}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group formSkew">
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      placeholder="Quantity"
                      onKeyUp={onKeyUp}
                      onChange={changeAmount}
                      value={amount}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="noteTextMessage">
                  Don't refresh the page when loading
                </div>
                <div className="text-center my-3">
                  <Button
                    className={classes.navLink + " create_btn btn-block mb-4"}
                    onClick={() => safetransfer()}
                    disabled={submitButton ? "true" : ""}
                  >
                    {submitButton && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                    Transfer
                  </Button>
                  <Button
                    className={classes.navLink + " create_btn_black btn-block"}
                    data-dismiss="modal"
                    onClick={() => cancelfunction()}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Transfer;
