/*eslint-disable*/
import React, { useEffect, useRef } from "react";
import { Notifications, AccountBalanceWallet, FileCopy } from '@material-ui/icons';
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import { Scrollbars } from "react-custom-scrollbars";

import $ from 'jquery';
import config from '../../config/config';

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import {
    headerSearch
} from "../../actions/users"

const useStyles = makeStyles(styles);

export default function HeaderSearch(props) {

    const classes = useStyles();
    const [itemList, setitemList] = React.useState([]);
    const [userList, setuserList] = React.useState([]);
    const [Keyword, Set_Keyword] = React.useState('');
    const [searchShow, setsearchShow] = React.useState(false);

    useEffect(() => {
        getSearchText();
    }, []);


    const searchChange = async (e) => {
      console.log('event',e.target)
        var value = e.target.value;
        console.log('keyword',e.target.value)
        if (value && value != "") {
            Set_Keyword(value);
            var response = await headerSearch({ search: value });
            if(response && response.data && response.data.result){
              setitemList(response.data.result);
            }
            if(response && response.data && response.data.users){
              setuserList(response.data.users);
            }
            setsearchShow(true);
        }else{
            setitemList([]);
            setuserList([]);
            Set_Keyword("");
        }
    }

    const keyPress = async (e) => {
        if (e.keyCode == 13) {
            console.log('value', e.target.value);
            window.location = "/search?query=" + e.target.value;
        }
    }

 
    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function getSearchText(){
        var searchtxt = getParameterByName('query');
        if(searchtxt){
            Set_Keyword(searchtxt)
        }
    }
    // let test = document.getElementById("usemneu_dd");

    // test.addEventListener("mouseleave", function( event ) {
    //   // highlight the mouseleave target
    //   setsearchShow(false)
    
    //   // reset the color after a short delay
    //   // setTimeout(function() {
    //   //   event.target.style.color = "";
    //   // }, 1000);
    // }, false);
    
    window.$('body').click(function() {
      setsearchShow(false)
      $('#searchinput').val('')
   });
    return (
        <List className={classes.list + " main_navbar"}>
            <ListItem className={classes.listItem}>

                <div className="search_inp_group">
                    <input type="text"  value={Keyword} onChange={searchChange} className="search_inp" id="searchinput" placeholder="Search item" />
                    <div className="search_inp_group_append">
                        <i className="fas fa-search"></i>
                    </div>
                </div>
                {searchShow &&
                    <div className="topSearchPopup noti_parent noti_parnt_user usemneu_dd" id="usemneu_dd">
                        {itemList && itemList.length > 0 &&
                             <div className="Item_list">
                                 <p className="list_head" style={{ alignItems:"center" }}>Tokens</p>
                                 <Scrollbars
                            style={{ width: 360, height: 500 }}
                            renderTrackVertical={(props) => (
                              <div className="track-vertical" />
                            )}
                          >
                                <ul className="noti_ul_dd">
                                    {
                                        itemList.map((item) => {
                                            return (
                                                <GridItem xs={12} sm={12} md={12}>
                                                <Link to={"/product-info/" + item.tokenId}>
                                                <div className="itemCard">
                                                  <div className="itemImage">
                                                  {
                                                      item.tokenName === "mp4" ?
                                                      <>
                                                        <img src={require("../../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                                                        </>
                                                        :
                                                        item.tokenName === "mp3" ?
                                                          <>
                                                            <img src={require("../../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                                            {/* <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" >
                                                              </audio> */}
                                                          </>
                                                          :
                                                          <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                                                    } 
                                                  </div>
                                                  <div className="itemDetails">
                                                    <h3>
                                                      {item.name}<br></br> <small>#{item.tokenId}</small>
                                                    </h3>
                                                    <p>
                                                      From: <span>{item.userId}</span>
                                                    </p>
                                                    <h4 className="itemPriceCrypto">
                                                      <img
                                                        src={require("../../assets/icon/favicon-32x32.png")}
                                                        className="img-fluid logo_img"
                                                        alt="Icon"
                                                      />{" "}
                                                      {item.price}{" "}
                                                      {item.tokentype == 1 ? " / BNB" :item.tokentype == 2 ?" / ZORO": " / ORO"}
                                                    </h4>
                                                    {/* <h4 className="itemPriceFiat">
                                                      <img
                                                        src={require("../assets/icon/favicon-32x32.png")}
                                                        className="img-fluid logo_img"
                                                        alt="Icon"
                                                      />{" "}
                                                      348.5 USD
                                                    </h4> */}
                                                    <h6>
                                                      <Link to={"/product-info/" + item.tokenId}>
                                                        <img
                                                          src={require("../../assets/images/itemArrow.png")}
                                                          className="img-fluid"
                                                          alt=""
                                                        />
                                                      </Link>
                                                    </h6>
                                                  </div>
                                                </div>
                                                </Link>
                                              </GridItem>
                                            )
                                        })
                                    }
                                </ul>
                              </Scrollbars>
                            </div>
                        }

                        {userList && userList.length > 0  &&
                                <div className="Item_list">
                                    <p className="list_head" style={{ alignItems:"center" }}>Tokens</p>
                                     <Scrollbars
                            style={{ width: 360, height: 500 }}
                            renderTrackVertical={(props) => (
                              <div className="track-vertical" />
                            )}
                          >
                                   <ul className="noti_ul_dd">
                                       {
                                           userList.map((item) => {
                                               return (
                                                   <GridItem xs={12} sm={12} md={12}>
                                                   <div className="itemCard">
                                                     <div className="itemImage">
                                                     {
                                                                item.tokenName === "mp4" ?
                                                                <>
                                                                  <img src={require("../../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                                                                  </>
                                                                  :
                                                                  item.tokenName === "mp3" ?
                                                                    <>
                                                                      <img src={require("../../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                                                      {/* <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" >
                                                                        </audio> */}
                                                                    </>
                                                                    :
                                                                    <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                                                              }
                                                     </div>
                                                     <div className="itemDetails">
                                                       <h3>
                                                         {item.name}<br></br> <small>#{item.tokenId}</small>
                                                       </h3>
                                                       <p>
                                                         From: <span>{item.userId}</span>
                                                       </p>
                                                       <h4 className="itemPriceCrypto">
                                                         <img
                                                           src={require("../../assets/icon/favicon-32x32.png")}
                                                           className="img-fluid logo_img"
                                                           alt="Icon"
                                                         />{" "}
                                                         {item.price}{" "}
                                                         {item.tokentype == 1 ? " / BNB" :item.tokentype == 2 ?" / ZORO": " / ORO"}
                                                       </h4>
                                                       {/* <h4 className="itemPriceFiat">
                                                         <img
                                                           src={require("../assets/icon/favicon-32x32.png")}
                                                           className="img-fluid logo_img"
                                                           alt="Icon"
                                                         />{" "}
                                                         348.5 USD
                                                       </h4> */}
                                                       <h6>
                                                         <Link to={"/product-info/" + item.tokenId}>
                                                           <img
                                                             src={require("../../assets/images/itemArrow.png")}
                                                             className="img-fluid"
                                                             alt=""
                                                           />
                                                         </Link>
                                                       </h6>
                                                     </div>
                                                   </div>
                                                 </GridItem>
                                               )
                                           })
                                       }
                                   </ul>
                                 </Scrollbars>
                               </div>
                           }
                        <div className="text-center">
                           {/* <button onClick={searchPage} class="btn new_btn_grad" tabindex="0" type="button">
                                <span>All Results </span>
                            </button> */}
                        </div>
                    </div>
                }

            </ListItem>
        </List>
    );
}
