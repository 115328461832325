import axios from "axios";
import config from "../config/config";
import Web3 from "web3";
import { toast } from "react-toastify";
toast.configure();

export const ipfsmetadata = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/ipfsmetadata`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errr");
    return {
      error: err.response.data,
    };
  }
};

export const checkwhiteaddress = async (payload) => {
   try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/checkwhiteaddress`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errr");
    return {
      error: err.response.data,
    };
  }
}

export const headerSearch = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/headerSearch`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errr");
    return {
      error: err.response.data,
    };
  }
};


export const ipfsImageHashGet = async (payload) => {
  console.log(payload, "payloadpayloadpayloadpayload");
  var formData = new FormData();
  if (payload.name) {
    formData.append("name", payload.name);
  }
  if (payload.TokenFile) {
    formData.append("TokenFile", payload.TokenFile);
  }

  if (payload.tokenId) {
    formData.append("tokenId", payload.tokenId);
  }

  try {
    // console.log("saran");
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/ipfsImageHashGet`,
      data: formData,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err, "errerrerrerrerrerrerr");
    return {
      error: err.response.data,
    };
  }
};

export const getuserdetail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getuserdetail`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const saveNft = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/saveNft`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const createtickets = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/createticket`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getticketlist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getticketlist`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const ticketclose = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/ticketclose`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const submitreply = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/submitreply`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const updatePutonSale = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/updatePutonSale`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const updateburn = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/updateburn`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const updateNotonSale = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/updateNotonSale`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const updateTransfer = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/updateTransfer`,
      data: data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const getCurAddr = async () => {
  var currAddr = "";
  if (localStorage.getItem("ronergodinalklz")) {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      if (window.web3 && window.web3.eth && window.web3.eth.defaultAccount) {
        var accVal = await web3.eth.getAccounts();
        if (accVal[0]) {
          currAddr = accVal[0];
        }
      }
    }
  }
  return currAddr;
};

export const getmyeventlist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}api/myeventlist`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const storeAddressinDb = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/storeAddressinDb`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTokens = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}front/getTokens`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaqlists = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}front/getfaqlists`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getepicdata = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}front/getepicdata`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getHotTokenstop = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getHotTokenstop`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsaleTokenstop = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getsaleTokenstop`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getownTokenstop = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getownTokenstop`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


// export const gethotToken = async (data) => {
//   try {
//     let respData = await axios({
//       method: "post",
//       url: `${config.baseurl}front/gethotToken`,
//       data: data,
//     });
//     console.log(respData, "respData.data");
//     return {
//       loading: false,
//       result: respData && respData.data ? respData.data : "",
//     };
//   } catch (err) {
//     console.log("err", err);
//     return {
//       loading: false,
//       error: err.response.data.errors,
//     };
//   }
// };

export const getTokenstop = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getTokenstop`,
      data: data,
    });
    console.log(respData, "respData.dataaaaaaaaaaaaaa---------------gettokenstoppp");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getNftdata = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}front/getNftdata/` + id,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getOwnerDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/getOwnerDetails/`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatePurchase = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/updatePurchase/`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatePrice = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}front/updatePrice/`,
      data: data,
    });
    console.log(respData, "respData.data");
    return {
      loading: false,
      result: respData && respData.data ? respData.data : "",
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const UserProfile_Update_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'headers': {
                'Content-Type': 'multipart/form-data'
      },
      'url': `${config.baseurl}front/profile/update`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
      loading: false,
      error:  err.response,
    }
  }
}
