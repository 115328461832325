import React, { useState, useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import styles from "./../../assets/jss/material-kit-react/views/landingPage.js";
import "@metamask/legacy-web3";
import ABI from "../../ABI/ABI.json";
import config from "../../config/config";
import { toast } from "react-toastify";
import ProductContext from "../ProductContext";
import Web3 from "web3";
import { updatePrice } from "../../actions/users";

toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);



export default function PriceUpdate(props) {
  const classes = useStyles();
  const productContext = useContext(ProductContext);
  var singdataNft = productContext.singdataNft;
  const [submitButton, setsubmitButton] = React.useState("");
  const { getNftdataID, saledata } = props;

  const [price, setPrice] = useState(singdataNft.price);
  const cancelfunction =()=>{
    setPrice("");
   }
  const changePrice = (price) => {
    setPrice(price.target.value);
  };

  if (saledata && saledata.satus === 0) {
    toast.error("NFT not in sale", toasterOption);
    return false;
  }

  async function changePriceSubmit() {
    if (price <= 0||price===undefined) {
      toast.error("Please Enter Valid Price", toasterOption);
      return false;
    }

    setsubmitButton(1);
    var web3 = new Web3(window.ethereum);
    var Contract = new web3.eth.Contract(ABI, config.NFTCONTRACT);
    var setacc = await  web3.eth.getAccounts();
    console.log("setacc===",setacc[0])
    console.log(saledata.offeringID,'offeringID');
    console.log(price,'price');
    var priceSend = price * 1000000000000000000;
    Contract.methods
      .updateOffering(
        saledata && saledata.offeringID,
        priceSend.toString()
      )
      .send({ from: setacc[0] })
      .then(async (result) => {
        var reqData = {
          price: price,
          id: saledata._id,
        };
        console.log(reqData, "reqDatareqDatareqData");
        await updatePrice(reqData);

        toast.success("Successfully updated", toasterOption);
        await getNftdataID();
        setTimeout(
          () =>
            (window.location.href =
              config.fronturl + "Product-info/" + saledata.tokenId),
          1200
        );
      })
      .catch((error) => {
        toast.error("User cancelled Change price", toasterOption);
        setsubmitButton("");
        console.log("Error");
      });
  }

  return (
    <div
      class="modal fade primary_modal"
      id="change_price_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="change_price_modalCenteredLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" id="hide">
          <div class="modal-header text-center">
            <h5 class="modal-title" id="change_price_modalLabel_1">
              Change Price
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={()=>cancelfunction()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="update_cover_div_2" id="update_cover_div_2">
              <form>
                <div className="form-group formSkew">
                  <label>Enter Price</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name=""
                      value={price}
                      className="form-control"
                      onChange={(e) => {
                        changePrice(e);
                      }}
                    />
                  </div>
                </div>
                <p class="form_note text-grey mb-0">
                </p>
                <p class="form_note text-grey mb-0">
                  You price will update to the new value
                  <span className="textYellow"> Are you sure ?</span>
                </p>
                <div className="noteTextMessage">Don't refresh the page when loading</div>
                <div className="text-center my-3">
                  <Button
                    className={classes.navLink + " create_btn btn-block mb-4"}
                    onClick={changePriceSubmit}
                    disabled={submitButton ? "true" : ""}
                  >
                    {submitButton && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                    Confirm
                  </Button>
                  <Button
                    className={classes.navLink + " create_btn_black btn-block"}
                    data-dismiss="modal"
                    onClick={()=>cancelfunction()}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
