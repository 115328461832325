import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button} from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getCurAddr, getTokenstop , getHotTokenstop } from "../actions/users";
import ConnectWallet from "./separate/Connect-Wallet";
import imageloader  from "../assets/images/imgload.gif";
const dashboardRoutes = [];


const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Explore(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [tokenList, setTokenList] = React.useState([]);
  const [HotTokenList,setHotTokenList] = React.useState([]);
  const [tokenListstatus, setTokenListstatus] = React.useState(true);
  const [HotTokenListstatus,setHotTokenListstatus] = React.useState(true);
  const [filtertype,setfiltertype] = React.useState("")
  const [selectedoption,setselectedoption] = React.useState("recent");
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const [Keyword, Set_Keyword] = React.useState('');
  const [tokenlistlimit, settokenlistlimit] = React.useState(9);
  const [hottokenlimit, sethottokenlimit] = React.useState(9);
   async function getTokensList() {
    let data={
      limit: tokenlistlimit
    }
    var tokenList = await getTokenstop(data);
    if(tokenList && tokenList.result && tokenList.result.data){
     setTokenList(tokenList.result.data);
     setTokenListstatus(false)
   }
  }

  const loadgetTokensList= async(event)=>{
    let limit = tokenlistlimit + 6 ;
    settokenlistlimit(limit)
    let data={
      limit: limit
    }
    var tokenList = await getTokenstop(data);
    if(tokenList && tokenList.result && tokenList.result.data){
     setTokenList(tokenList.result.data);
     setTokenListstatus(false)
   }
  }


  async function AfterWalletConnected() {
    try {
      if (localStorage.getItem("ronergodinalklz")) {
        var curAddr = await getCurAddr();
        if (curAddr === localStorage.getItem("address")) {
          localStorage.setItem("ronergodinalklz", "yes");
          localStorage.setItem("address", curAddr);
          // setacc[0]=curAddr;
        } else {
          localStorage.clear();
          window.location.href = "/home";
        }
      }
    } catch (err) {}
  }

  async function getHotcollectionlist() {
    let data={
      limit: hottokenlimit
    }
    var hottokenList = await getHotTokenstop(data);
    if(hottokenList && hottokenList.result && hottokenList.result.data){
      setHotTokenList(hottokenList.result.data);
      setHotTokenListstatus(false);
    }
  }

  const loadgetHotcollectionlist= async(event)=>{
    let limit = hottokenlimit + 6 ;
    sethottokenlimit(limit)
    var data={
      limit: limit
    }
    var hottokenList = await getHotTokenstop(data);
    if(hottokenList && hottokenList.result && hottokenList.result.data){
      setHotTokenList(hottokenList.result.data);
      setHotTokenListstatus(false);
    }
  }

  const  filterchange = async (e) =>{
    setfiltertype(e.target.value)
    var data={
      "filter":e.target.value,
      "search":Keyword
    }
    if(selectedoption === "recent"){
      var tokenList = await getTokenstop(data);
      if(tokenList && tokenList.result && tokenList.result.data){
       setTokenList(tokenList.result.data);
       setTokenListstatus(false)
     }
    }else{
      var hottokenList = await getHotTokenstop(data);
      if(hottokenList && hottokenList.result && hottokenList.result.data){
        setHotTokenList(hottokenList.result.data);
        setHotTokenListstatus(false)
      }
    }
  }


  async function recentclick(){
    getTokensList()
    setselectedoption("recent")
    Set_Keyword("")
    setfiltertype("")
  }

  async function hotclick(){
    getHotcollectionlist()
    setselectedoption("hot")
    Set_Keyword("")
    setfiltertype("")
  }

  useEffect(() => {
    getTokensList();
    getHotcollectionlist();
  }, []);

  const searchChange = async (e) => {
      var value = e.target.value;
      Set_Keyword(value)
      if (value && value !== "") {
          Set_Keyword(value);
           var data={
              "filter":filtertype,
              "search":value
            }
            if(selectedoption==="recent"){
              var tokenList = await getTokenstop(data);
              if(tokenList && tokenList.result && tokenList.result.data){
               setTokenList(tokenList.result.data);
               setTokenListstatus(false)
             }
            }else{
              var hottokenList = await getHotTokenstop(data);
              if(hottokenList && hottokenList.result && hottokenList.result.data){
                setHotTokenList(hottokenList.result.data);
              }
            }
      }else{
          Set_Keyword("");
          getTokensList();
          getHotcollectionlist();
      }
    }

  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img
              src={require("../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title"> Explore</h2>
              </div>
            </GridItem>
          </GridContainer>
          <div className="exploreFilter">
            <div className="filterLeft">
              <nav>
                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link create_btn active"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    onClick={()=>recentclick()}
                  >
                    <span>Recently Listed</span>
                  </a>
                  <a
                    class="nav-item nav-link create_btn"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                     onClick={()=>hotclick()}
                  >
                    <span>Hot Token List</span>
                  </a>
                </div>
              </nav>
            </div>
            <div className="search_inp_group">
                <input type="text" value={Keyword} onChange={searchChange} className="search_inp" id="searchinput" placeholder="Search item" />
                <div className="search_inp_group_append">
                    <i className="fas fa-search"></i>
                </div>
            </div>
            <div className="filterRight">
              <div className="form-group mb-0">
                <label className="mr-2">Sort</label>
                <Select
                  className={classes.selectEmpty + " selectSkew w-100"}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={filterchange}
                >
                     <MenuItem value="recent" >Recently Listed</MenuItem>
                  <MenuItem value="oldest">Oldest Listed</MenuItem>
               
                  <MenuItem value="high" >Price High</MenuItem>
                  <MenuItem value="low" >Price Low</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div class="tab-content pt-4" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              {tokenListstatus &&
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div className="itemCard">
                  <img src={imageloader} alt="Collections" />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              </GridContainer >
                }
              <GridContainer>
                {tokenList.map((item, index) => {
                  return (
                    <GridItem xs={12} sm={12} md={4}>
                      <Link to={"/product-info/" + item.tokenId}> 
                      <div className="itemCard">
                        <div className="itemImage">
                        {
                            item.tokenName === "mp4" ?
                            <>
                              <img src={require("../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                              </>
                              :
                              item.tokenName ===  "mp3" ?
                                <>
                                  <img src={require("../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                  
                                </>
                                :
                                <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                          }
                        </div>
                        <div className="itemDetails">
                          <h3>
                            {item.name}<br></br><small>#{item.tokenId}</small>
                          </h3>
                          <p>
                            From: <span>{item.userId}</span>
                          </p>
                          <h4 className="itemPriceCrypto">
                            <img
                              src={require("../assets/icon/favicon-32x32.png")}
                              className="img-fluid logo_img"
                              alt="Icon"
                            />{" "}
                            {item.price}{" "}
                            {item.tokentype === 1 ? " / BNB" :item.tokentype === 2 ?" / ZORO": " / ORO"}
                          </h4>
                          <h6>
                            <Link to={"/product-info/" + item.tokenId}>
                              <img
                                src={require("../assets/images/itemArrow.png")}
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                          </h6>
                        </div>
                      </div>
                      </Link>
                    </GridItem>
                  );
                })}
              </GridContainer>
              {tokenList &&
               tokenList.length > 0 &&<Button onClick={loadgetTokensList} className={classes.navLink + " create_btn px-4"}>
                        Load More
              </Button>}
            </div>
            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              {HotTokenListstatus &&
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div className="itemCard">
                  <img src={imageloader} alt="Collections" />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              <div className="itemCard">
              <img src={imageloader} alt="Collections" />
              </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className="itemCard">
                <img src={imageloader} alt="Collections" />
                </div>
              </GridItem>
              </GridContainer >
                }
              <GridContainer>
                {HotTokenList.map((item, index) => {
                  return (
                    <GridItem xs={12} sm={12} md={4}>
                   <Link to={"/product-info/" + item.tokenId}> 
                      <div className="itemCard">
                        <div className="itemImage">
                        {
                            item.tokenName === "mp4" ?
                            <>
                              <img src={require("../assets/images/video.jpg")} alt="Collections" className="img-fluid" />
                              </>
                              :
                              item.tokenName === "mp3" ?
                                <>
                                  <img src={require("../assets/images/music.jpg")} alt="" className="img-fluid " controls />
                                </>
                                :
                                <img src={item.imageDataUrl} alt="Collections" className="img-fluid " />
                          }
                        </div>
                        <div className="itemDetails">
                          <h3>
                          {item.name}<br></br><small>#{item.tokenId}</small>
                          </h3>
                          <p>
                            From: <span>{item.userId}</span>
                          </p>
                          <h4 className="itemPriceCrypto">
                            <img
                              src={require("../assets/icon/favicon-32x32.png")}
                              className="img-fluid logo_img"
                              alt="Icon"
                            />{" "}
                            {item.price}{" "}
                            {item.tokentype === 1 ? " / BNB" :item.tokentype === 2 ?" / ZORO": " / ORO"}
                          </h4>
                          <h6>
                          <Link to={"/product-info/" + item.tokenId}>
                              <img
                                src={require("../assets/images/itemArrow.png")}
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </Link>
                    </GridItem>
                  );
                })}
              </GridContainer>
              {HotTokenList &&
               HotTokenList.length > 0 &&<Button onClick={loadgetHotcollectionlist} className={classes.navLink + " create_btn px-4"}>
                        Load More
              </Button>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        class="modal fade primary_modal"
        id="edit_cover_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="edit_cover_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="edit_cover_modalLabel_1">
                Update cover
              </h5>
              <h5 class="modal-title d-none" id="edit_cover_modalLabel_2">
                Follow Steps
              </h5>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">
                  Upload new cover for your profile page. We recommended to
                  upload images in 1140×260 resolution
                </p>
                <form className="text-center">
                  <div className="file_btn btn primary_btn">
                    Choose image
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </form>
              </div>
              <div
                className="update_cover_div_2 d-none"
                id="update_cover_div_2"
              >
                <div className="media approve_media">
                  <i
                    class="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Inprogress
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade primary_modal"
        id="follow_steps_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="follow_steps_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content" id="hide">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="follow_steps_modalLabel_1">
                Follow Steps
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <div className="media approve_media">
                  <i
                    class="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign Sell Order</p>
                    <p className="mt-0 approve_desc">
                      Sign sell order using your wallet
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Follow Wallet Instructions
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
