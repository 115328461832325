import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "./main.css";

// pages
import Home from "views/Home.js";
import CreateSingle from "views/Create-single.js";
import EditProfile from "views/edit-profile.js";
import Myitems from "views/my-items.js";
import Explore from "views/explore.js";
import ProductInfo from "views/product-info.js";
import Terms from "views/Termsofservice.js";
import Privacypolicy from "views/Privacypolicy.js";
import HowItWorks from "views/how-it-works.js";
import connect from "views/connect-wallet.js";
import SupportTicket from "views/support-ticket.js";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <BrowserRouter basename="/">
    <ToastContainer />
    <Switch>
      <Route path="/connect" component={connect} />
      <Route path="/explore" component={Explore} />
      <Route path="/faq" component={HowItWorks} />
      <Route path="/privacy" component={Privacypolicy} />
      <Route path="/terms" component={Terms} />
      <Route path="/product-info/:id" component={ProductInfo} />
      <Route path="/my-items" component={Myitems} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/support-ticket" component={SupportTicket} />
      <Route path="/create-single" component={CreateSingle} />
      <Route path="/home" component={Home} />
      <Route exact path="/*" component={Home}>
        <Redirect to="/home" />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
