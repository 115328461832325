/*eslint-disable*/
import React,  { useState, useEffect }  from "react";
import {
  Notifications,
  AccountBalanceWallet,
  FileCopy,
} from "@material-ui/icons";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button } from "@material-ui/core";
import Heardersearch from "../../views/separate/HeaderSearch"

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { checkwhiteaddress } from '../../actions/users';


const useStyles = makeStyles(styles);

export default function HeaderLinksAfterLogin(props) {

  const [createshow,setcreateshow] = useState(false)

  useEffect(() => {
    getUserData();
  }, [])

  async function getUserData(){
    
    if (localStorage.getItem("ronergodinalklz")) {
      var add = await localStorage.getItem("address");
       console.log('surain',add);
      var data={
        "address" : add
      }
      var test = await checkwhiteaddress(data);
      if(test && test.data && test.data.data && test.data.data.length>0){
        setcreateshow(true)
      }else{
        setcreateshow(false)
      }
    }
  }

  async function disconnectMetamask() {
    // alert('hi')
    if (localStorage.getItem("ronergodinalklz")) {
      localStorage.removeItem("ronergodinalklz");
      localStorage.removeItem("address");
      localStorage.removeItem("balance");
      // localStorage.setItem("disconnect",true)

    }
    window.location.href="/"
  }

  const classes = useStyles();
  return (
    <List className={classes.list + " main_navbar"}>
      {/*<ListItem className={classes.listItem}>
        <div className="search_inp_group formSkew">
          <input
            type="text"
            className="search_inp form-control"
            placeholder="Search items / creators"
          />
          <div className="search_inp_group_append">
            <i className="fas fa-search"></i>
          </div>
        </div>
      </ListItem> */}
      <Heardersearch />
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/home">
          Explore
        </NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/my-items">
          My items
        </NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/faq">
          FAQ
        </NavLink>
      </ListItem>
      {createshow && <ListItem className={classes.listItem + " ml-3"}>
        <Button className={classes.navLink + " create_btn"}>
          <Link to="/create-single">Create</Link>
        </Button>
      </ListItem>}
      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul noti_ul marginSideMenuSpace"
        }
      >
        <CustomDropdown
          noLiPadding
          buttonText={
            <div>
              <Button className={classes.navLink + " create_btn"}>
                <Notifications className="menu_icons" />
              </Button>
            </div>
          }
          dropdownList={[
            <div className="noti_parent">
              <p className="noti_head">Notifications</p>
              <p className="no_noti">No new notifications</p>
              <Link to="/" className={classes.dropdownLink + " see_all_link"}>
                See all
              </Link>
            </div>,
          ]}
        />
      </ListItem>
      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul noti_ul wallet_drop marginSideMenuSpace"
        }
      >
        <CustomDropdown
          noLiPadding
          buttonText={
            <div>
              <Button className={classes.navLink + " create_btn"}>
                <AccountBalanceWallet className="menu_icons" />
              </Button>
            </div>
          }
          dropdownList={[
            <div>
              <div className="noti_parent">
                <div className="d-flex align-items-center">
                  <p className="wallet_address">
                    0x75Cf28f29266A413eC878CE5C4a1c9682C819586
                  </p>{" "}
                  <FileCopy className="ml-2 wllet_copy_icon text-white" />
                </div>
                <div className="wallet_balance">
                  <h4>Balance</h4>
                  <p>0 BNB</p>
                </div>
              </div>
              <hr />
              <div className="noti_parent">
                <NavLink to="/my-items" className={classes.dropdownLink}>
                  My Items
                </NavLink>
                <NavLink to="/edit-profile" className={classes.dropdownLink}>
                  Edit Profile
                </NavLink>
                <Button
                  type="submit"
                  className={classes.dropdownLink + " p-0 ml-0"}
                  onClick={() => disconnectMetamask()}
                >
                  Disconnect
                </Button>
              </div>
            </div>,
          ]}
        />
      </ListItem>
    </List>
  );
}
